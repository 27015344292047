import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { paramsSerializer } from './helpers';
import { RootState } from '../store';
import { Operation, OperationParameters, OperationSuccessResponse } from '../utils/api-schema-utils';

type GetPackages = Operation<'/booking/packages', 'get'>;
type GetPublicPackages = Operation<'/booking/available-packages', 'get'>;
type GetResourceOptions = Operation<'/booking/resource-options', 'get'>;

export type Package = OperationSuccessResponse<GetPackages>[number];
export type ResourceOption = OperationSuccessResponse<GetResourceOptions>[number];
export type ResourceOptionType = ResourceOption['resource_type'];

export const BOOKING_API_BASE = '/api/v1/booking';

// Define a service using a base URL and expected endpoints
export const bookingApi = createApi({
  reducerPath: 'bookingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BOOKING_API_BASE,
    paramsSerializer,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPackages: builder.query<OperationSuccessResponse<GetPackages>, OperationParameters<GetPackages>>({
      query: (params) => ({
        url: `/packages`,
        params,
      }),
    }),
    // Unauthenticated endpoint to retrieve the current existing packages
    getPublicPackages: builder.query<
      OperationSuccessResponse<GetPublicPackages>,
      OperationParameters<GetPublicPackages>
    >({
      query: (params) => ({
        url: `/available-packages`,
        params,
      }),
    }),
    getResourceOptions: builder.query<
      OperationSuccessResponse<GetResourceOptions>,
      OperationParameters<GetResourceOptions>
    >({
      query: (params) => ({
        url: `/resource-options`,
        params,
      }),
    }),
  }),
});

export const { useGetPackagesQuery, useGetResourceOptionsQuery, useGetPublicPackagesQuery } = bookingApi;
