import { createRoute } from '@tanstack/react-router';
import { calculatorSearchSchema } from '../../components/Calculator/utils';
import { Route as RootRoute } from '../__root';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Calculator',
    },
  ],
  getParentRoute: () => RootRoute,
  path: 'calculator',
  validateSearch: (search) => calculatorSearchSchema.parse(search),
}).lazy(() => import(/* webpackChunkName: "calculator" */ './calculator.lazy').then(({ Route }) => Route));
