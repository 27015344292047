import { useMatches } from '@tanstack/react-router';
import { ReactNode, useEffect } from 'react';
import { MIDDOT_SYMBOL } from '../utils/constants';

export const META_TITLE = 'Qdrant Cloud';

export function Meta({ children }: { children: ReactNode }) {
  const matches = useMatches();
  const meta = matches.at(-1)?.meta?.find((meta) => meta.title);

  useEffect(() => {
    document.title = [meta?.title, META_TITLE].filter(Boolean).join(` ${MIDDOT_SYMBOL} `);
  }, [meta]);

  return children;
}
