import { useRouteContext } from '@tanstack/react-router';

/**
 * Returns the current authentication context from the router state.
 */
export const useAuth = () => useRootContext().auth;

/**
 * Returns the context available in the Root route
 */
export const useRootContext = () => useRouteContext({ from: '__root__' });
