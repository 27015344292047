import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { endpoints as accessManagementhEndpoints } from './access-management';
import { FromPathParams, MutationOptions, QueryData, QueryOptions, queryKey, toPathParams } from './utils';
import { useRootContext } from '../../router/hooks';
import { iamApi } from '../../services/iamApi';
import { store } from '../../store';
import { client } from '../client';

export type UserInvitesData = QueryData<typeof endpoints.userInvites>;
export type UserInviteData = UserInvitesData[number];
export type UserInviteStatus = UserInviteData['status'];

export const endpoints = {
  users: '/accounts/{account_id}/users',
  user: '/accounts/{account_id}/users/{user_id}',
  userRoles: '/accounts/{account_id}/auth/users/{user_id}/roles',

  accountInvites: '/accounts/{account_id}/invites',
  userInvites: '/users/{user_id}/invites',

  accountInvite: '/accounts/{account_id}/invites/{invite_id}',
  userInvite: '/users/{user_id}/invites/{invite_id}',
} as const;

export const getUsersByAccountId = (params: FromPathParams<QueryOptions<typeof endpoints.users>>) =>
  queryOptions<QueryData<typeof endpoints.users>>({
    queryKey: queryKey.get(endpoints.users, params),
  });

export const getInvitesByAccountId = (params: FromPathParams<QueryOptions<typeof endpoints.accountInvites>>) =>
  queryOptions<QueryData<typeof endpoints.accountInvites>>({
    queryKey: queryKey.get(endpoints.accountInvites, params),
  });

export const getInvitesByUserId = (params: FromPathParams<QueryOptions<typeof endpoints.userInvites>>) =>
  queryOptions<UserInvitesData>({
    queryKey: queryKey.get(endpoints.userInvites, params),
  });

export const useUpdateUserInviteMutation = <
  TOptions extends MutationOptions<typeof endpoints.userInvite, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.patch(endpoints.userInvite, params),
    mutationFn: async (body: TVariables) => {
      const { data } = await client.PATCH(endpoints.userInvite, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess(_data, { status }) {
      if (status === 'ACCEPTED') {
        store.dispatch(iamApi.util.invalidateTags(['UserInfo']));
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [endpoints.userInvites, toPathParams(params, { filterKeys: ['user_id'] })],
      });
      await router.invalidate();
    },
  });
};

export const useUpdateUserRolesMutation = <
  TOptions extends MutationOptions<typeof endpoints.userRoles, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.patch(endpoints.userRoles, params),
    mutationFn: async (body: TVariables) => {
      const { data } = await client.PATCH(endpoints.userRoles, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [endpoints.users, toPathParams(params, { filterKeys: ['account_id'] })],
      });
      await queryClient.invalidateQueries({
        queryKey: [accessManagementhEndpoints.roles, toPathParams(params, { filterKeys: ['account_id'] })],
      });
      await router.invalidate();
    },
  });
};

export const useRemoveUserFromAccountMutation = <
  TOptions extends MutationOptions<typeof endpoints.user, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.delete(endpoints.user, params),
    mutationFn: async (args: Pick<TParams, 'user_id'>) => {
      const { data } = await client.DELETE(endpoints.user, {
        params: {
          path: {
            account_id: params.account_id,
            user_id: args.user_id,
          },
        },
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.users, params) });
      await router.invalidate();
    },
  });
};

export const useInviteUserToAccountMutation = <
  TOptions extends MutationOptions<typeof endpoints.accountInvites, 'post'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();
  const pathParams = toPathParams(params);

  return useMutation({
    mutationKey: queryKey.post(endpoints.accountInvites, params),
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(endpoints.accountInvites, { ...pathParams, body });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.accountInvites, params) });
      await router.invalidate();
    },
  });
};

export const useRevokeInviteUserToAccountMutation = <
  TOptions extends MutationOptions<typeof endpoints.accountInvite, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.delete(endpoints.accountInvite, params),
    mutationFn: async (args: Pick<TParams, 'invite_id'>) => {
      const { data } = await client.DELETE(endpoints.accountInvite, {
        params: {
          path: {
            account_id: params.account_id,
            invite_id: args.invite_id,
          },
        },
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.accountInvites, params) });
      await router.invalidate();
    },
  });
};
