import { createRoute } from '@tanstack/react-router';
import { Route as HybridCloudEnvironmentRoute } from './$hybridCloudEnvId';
import { MIDDOT_SYMBOL } from '../../../utils/constants';

export const Route = createRoute({
  meta: () => [
    {
      title: `Hybrid Cloud Environment ${MIDDOT_SYMBOL} Edit`,
    },
  ],

  getParentRoute: () => HybridCloudEnvironmentRoute,
  path: 'edit',
}).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-env-edit" */ './$hybridCloudEnvId.edit.lazy').then(({ Route }) => Route),
);
