import { styled } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { AccountDashboardNavbar, AccountDashboardAppBar } from './AccountDashboardBar';
import { AccountDashboardSidebar } from './AccountDashboardSidebar';

export const DASHBOARD_LAYOUT_GAP = '64px';

const AccountDashboardContent = styled('div', {
  shouldForwardProp: (propName) => propName !== 'noSidebar',
})<{ noSidebar?: boolean }>(({ theme, noSidebar }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: DASHBOARD_LAYOUT_GAP,
  ...(!noSidebar && {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 280,
    },
  }),
}));

const AccountDashboardBody = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
});

/**
 * Each authenticated dashboard page should be wrapped in this component.
 * It provides the layout and the sidebar. An error boundary is also provided for the children.
 */
export const AccountDashboard = ({ children }: { children: ReactNode }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const onOpenSidebar = useCallback(() => {
    setIsSidebarOpen(true);
  }, []);
  const onCloseSidebar = useCallback(() => {
    setIsSidebarOpen(false);
  }, []);

  return (
    <>
      <AccountDashboardContent>
        <AccountDashboardBody>{children}</AccountDashboardBody>
      </AccountDashboardContent>
      <AccountDashboardNavbar onOpenSidebar={onOpenSidebar} />
      <AccountDashboardSidebar onClose={onCloseSidebar} open={isSidebarOpen} />
    </>
  );
};

export const AccountDashboardOutline = ({ children }: { children?: ReactNode }) => (
  <AccountDashboardContent noSidebar={true}>
    <AccountDashboardBody>{children}</AccountDashboardBody>
    <AccountDashboardAppBar noSidebar={true} />
  </AccountDashboardContent>
);
