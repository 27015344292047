import {
  Backdrop,
  Box,
  Container,
  Theme,
  Typography,
  useMediaQuery,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactNode, memo } from 'react';
import { VersionAlert } from './VersionAlert';
import { SettingsData } from '../../api/services/core';

export const Maintenance = ({ children }: { children: ReactNode }) => {
  const { data: maintenance } = useQuery({
    queryKey: ['settings'],
    select: ({ maintenance }: SettingsData) => maintenance,
  });

  if (maintenance) {
    return <MaintenanceBanner />;
  }
  return (
    <>
      {children}
      <VersionAlert />
    </>
  );
};

const MaintenanceBanner = memo(function MaintenanceBanner() {
  const theme = useTheme<Theme>();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  return (
    <Backdrop sx={{ zIndex: theme.zIndex.drawer + 800 }} open={true}>
      <Box
        sx={{
          minWidth: '100vw',
          minHeight: '100vh',
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexGrow: 1,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
            Down for maintenance
          </Typography>
          <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
            We are currently down for maintenance, please check back later
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={`/images/error/error401_${theme.palette.mode}.svg`}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
        </Container>
      </Box>
    </Backdrop>
  );
});
