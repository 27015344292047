/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ComponentSchema } from '../../utils/api-schema-utils';

type QdrantErrorCode = ComponentSchema<'QdrantErrorCode'>;

export function isFetchMutationError(result: unknown): result is { error: FetchBaseQueryError | SerializedError } {
  return typeof result === 'object' && result != null && 'error' in result;
}

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isFetchApiError(error: unknown): error is FetchApiError {
  return error instanceof FetchApiError;
}

export function isErrorWithDetail(error: unknown): error is { detail: QdrantErrorCode } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof error === 'object' && error != null && 'detail' in error && typeof (error as any).detail === 'string';
}

abstract class FetchError extends Error {
  constructor(
    message?: string,
    readonly options?: ErrorOptions,
  ) {
    super(message, options);
    this.name = 'FetchError';
  }
}

// An error that occurred during execution of `fetch` or the `fetchFn` callback option
class FetchTypeError extends FetchError {
  constructor(message: string) {
    super(message);
    this.name = 'FetchTypeError';
  }
}

// Request timed out in RTK Query
class FetchTimeoutError extends FetchError {
  constructor(message: string) {
    super(message);
    this.name = 'FetchTimeoutError';
  }
}

class FetchParsingError extends FetchError {
  /**
   * An error happened during parsing.
   * Most likely a non-JSON-response was returned with the default `responseHandler` "JSON",
   * or an error occurred while executing a custom `responseHandler`.
   */
  constructor(
    readonly originalStatus: number,
    readonly error: string,
    readonly data: string,
  ) {
    super();
    this.name = 'FetchParsingError';
  }

  override get message() {
    return `(HTTP ${this.originalStatus}) ${this.error} <${JSON.stringify(this.data)}>`;
  }
}

class FetchApiError extends FetchError {
  /**
   * An error was sent from the API server.
   * If it contains useful information, it will be in the `meta` field.
   */
  constructor(
    readonly originalStatus: number,
    readonly data: unknown,
  ) {
    super();
    this.name = 'FetchApiError';
  }

  override get message() {
    const maybeCode = this.code;
    let data;
    try {
      data = JSON.stringify(this.data);
    } catch {
      data = String(this.data);
    }
    return `(HTTP ${this.originalStatus}) ${maybeCode ? maybeCode : `<${this.detail ?? data}>`}`;
  }

  get detail() {
    const error = this.data as ComponentSchema<'HTTPValidationError'>;
    if (isErrorWithDetail(error)) {
      return error.detail;
    }
    return null;
  }

  get code() {
    const error = this.data as ComponentSchema<'HTTPValidationError'>;
    if (isErrorWithDetail(error)) {
      if (/^E(\d{4})$/.test(error.detail)) {
        return error.detail as QdrantErrorCode;
      }
    }
    return null;
  }
}

class FetchSerializedError extends FetchError {
  constructor(readonly error: SerializedError) {
    super(undefined, { cause: error });
  }

  override get message(): string {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.error.message || 'Fetch serialized error';
  }
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export function parseFetchError(error: FetchBaseQueryError | SerializedError | unknown) {
  // Error came from the server, or the connection failed, or there was a timeout...
  if (isFetchBaseQueryError(error)) {
    if (typeof error.status === 'number') {
      return new FetchApiError(error.status, error.data);
    }
    switch (error.status) {
      case 'PARSING_ERROR':
        return new FetchParsingError(error.originalStatus, error.error, error.data);
      case 'TIMEOUT_ERROR':
        return new FetchTimeoutError(error.error);
      default:
        return new FetchTypeError(error.error);
    }
  }
  // Some internal RTK error unrelated to the actual fetch response
  return new FetchSerializedError(error as SerializedError);
}

export function paramsSerializer(params: Record<string, unknown>): string {
  const urlParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((singleValue) => {
          urlParams.append(key, encodeURIComponent(String(singleValue)));
        });
      } else if (value instanceof Object) {
        throw new Error('Not implemented');
      } else {
        urlParams.set(key, encodeURIComponent(String(value)));
      }
    }
  });
  return decodeURIComponent(urlParams.toString());
}
