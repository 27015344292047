import { createRoute, redirect } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '..';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Cloud UI Access',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'cloud-access',
  beforeLoad: ({ params, ...rest }) => {
    const isExactMatch = rest.matches.at(-1)?.routeId === Route.id;

    if (isExactMatch) {
      throw redirect({ to: '/accounts/$accountId/cloud-access/roles/all-users', params });
    }
  },
}).lazy(() => import(/* webpackChunkName: "cloud-access" */ './index.lazy').then(({ Route }) => Route));
