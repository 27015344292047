import { createRoute } from '@tanstack/react-router';
import { MIDDOT_SYMBOL } from '../../../utils/constants';
import { Route as HybridCloudEnvironmentsRoute } from '../hybrid-cloud-environments_';

export const Route = createRoute({
  meta: () => [
    {
      title: `Hybrid Cloud ${MIDDOT_SYMBOL} Onboarding`,
    },
  ],
  getParentRoute: () => HybridCloudEnvironmentsRoute,
  path: 'onboarding',
}).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-envs-onboarding" */ './onboarding.lazy').then(({ Route }) => Route),
);
