import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '.';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Profile',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'profile',
}).lazy(() => import(/* webpackChunkName: "profile" */ './profile.lazy').then(({ Route }) => Route));
