import { Link, Typography } from '@mui/material';
import { AccountDashboardMain } from '../../components/AccountDashboard/AccountDashboardMain';

export function LegalComponent() {
  return (
    <AccountDashboardMain title="Legal information" hasTopMenu={false}>
      <Typography variant="h4">Credits</Typography>
      <Typography variant="h6">We are using images from:</Typography>
      <Link href="https://www.vecteezy.com/free-vector/login" target="_blank">
        Login Vectors by Vecteezy
      </Link>
    </AccountDashboardMain>
  );
}
