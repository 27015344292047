import { FormControl, FormGroup, FormHelperText, Grid, Input, Slider, styled } from '@mui/material';
import { ChangeEvent, FocusEvent, memo, useCallback, useMemo, useState } from 'react';
import { useClusterConfig, useClusterConfigUpdater, useDefaultClusterConfig } from './ClusterConfigurationContext';

export const MIN_DISK_SPACE = 0;
export const MAX_DISK_SPACE = 2048;

const StyledSliderContainer = styled('div')`
  display: flex;
`;

const StyledInactiveTrack = styled('span', { shouldForwardProp: (prop) => prop !== 'min' })<{ min: number }>(
  ({ theme, min }) => ({
    width: `${Math.sqrt((min ** 2 / MAX_DISK_SPACE) * 100)}%`,
    height: '4px',
    marginTop: '13px',
    borderRadius: '12px',
    backgroundColor: theme.palette.inactive.main,
  }),
);

export const ClusterDiskSpaceControl = ({ disabled }: { disabled?: boolean }) => {
  const {
    additionalDisk: defaultAdditionalDisk,
    disk: defaultDisk,
    complimentaryDisk: defaultComplimentaryDisk,
  } = useDefaultClusterConfig();
  const {
    disk,
    complimentaryDisk,
    additionalDisk,
    diskValues: [diskValue],
  } = useClusterConfig();

  const value = disk + additionalDisk + complimentaryDisk;
  const minValue = defaultDisk + defaultAdditionalDisk + defaultComplimentaryDisk;

  return (
    <ClusterDiskSpaceControlInner
      value={value}
      minValue={defaultDisk === diskValue ? minValue : Math.max(minValue, diskValue)}
      disabled={disabled}
    />
  );
};

const getAriaValueText = (value: number) => `${value} GB`;

const calculateValue = (value: number) => 2 ** value;

const getValidInputValue = (event: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>, minValue: number) =>
  event.target.value === '' ? minValue : Math.round(Number(event.target.value));

export const ClusterDiskSpaceControlInner = memo(function ClusterDiskSpaceControlInner({
  value,
  minValue,
  disabled,
}: {
  value: number;
  minValue: number;
  disabled?: boolean;
}) {
  const update = useClusterConfigUpdater();
  const [editingValue, setEditingValue] = useState<null | number | ''>(null);
  const marks = useMemo(
    () => [
      { value: minValue, label: `${minValue} GB` },
      { value: MAX_DISK_SPACE, label: `${MAX_DISK_SPACE / 1024} TB` },
    ],
    [minValue],
  );
  const handleSliderChange = useCallback(
    (_: Event, value: number | number[]) => {
      update({ type: 'disk', value: value as number });
    },
    [update],
  );
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = Math.max(0, Math.min(getValidInputValue(event, minValue), MAX_DISK_SPACE));
      const editingValue = Number(event.target.value);
      if (editingValue < minValue) {
        setEditingValue(editingValue || '');
      } else {
        setEditingValue(null);
        update({ type: 'disk', value });
      }
    },
    [update, minValue],
  );
  const handleInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setEditingValue(null);
      update({ type: 'disk', value: Math.max(getValidInputValue(event, minValue), minValue) });
    },
    [update, minValue],
  );

  return (
    <FormControl component="fieldset" variant="standard" fullWidth={true} required={true}>
      <FormGroup>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={10}>
            <StyledSliderContainer>
              <StyledInactiveTrack min={minValue} />
              <Slider
                value={value}
                aria-label="Restricted values"
                onChange={handleSliderChange}
                getAriaValueText={getAriaValueText}
                marks={marks}
                step={1}
                min={minValue}
                max={MAX_DISK_SPACE}
                scale={calculateValue}
                disabled={disabled}
              />
            </StyledSliderContainer>
          </Grid>
          <Grid item={true} xs={2}>
            <Input
              value={editingValue ?? value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              inputProps={{
                step: 1,
                min: minValue,
                max: MAX_DISK_SPACE,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <FormHelperText>
          Please note, once increased, the disk space cannot be decreased later.
          <br />
          This change is irreversible.
        </FormHelperText>
      </FormGroup>
    </FormControl>
  );
});
