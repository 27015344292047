import { createEventTrackingHook } from '@qdrant/qdrant-analytics-events';
import { useLocation } from '@tanstack/react-router';
import { useCallback, useMemo } from 'react';
import { useAnalyticsContext } from '../components/Analytics/AnalyticsProvider';
import { Cookies } from '../utils/cookies';

let gaSessionId: string | undefined;
let gaClientId: string | undefined;

// Google Analytics Cookies
function getGAProperties() {
  if (!(gaSessionId && gaClientId)) {
    const gaMeasurementId = window.__QDRANT_CLOUD__.ga_measurement_id.replace('G-', '');
    gaSessionId = Cookies.get(`_ga_${gaMeasurementId}`)?.replace('GS1.1.', '').split('.')[0];
    gaClientId = Cookies.get('_ga')?.replace('GA1.1.', '');
  }
  return { ga_session_id: gaSessionId, ga_client_id: gaClientId };
}

type EventPayload = Record<string, unknown>;

type PayloadWithTimestamp = {
  timestamp?: Date;
} & EventPayload;

const _useEventTracking = createEventTrackingHook((eventName, eventPayload) => {
  const { timestamp, ...eventProperties } = eventPayload as PayloadWithTimestamp;
  void window.analytics.track(
    eventName,
    {
      ...eventProperties,
      ...getGAProperties(),
    },
    { timestamp },
  );
});

export function useEventTracking<
  T extends Parameters<typeof _useEventTracking>[0],
  TEventName extends T['eventName'],
  TEventPayload extends T['eventPayload'],
>(eventName: TEventName, eventPayload?: TEventPayload) {
  return useTrackEventQueue(_useEventTracking({ eventName, eventPayload }));
}

/**
 * Queue event calls until cookie consent is granted, then dispatch them all
 */
function useTrackEventQueue<T extends (...args: any[]) => void>(trackEvent: T) {
  const { enqueueEvent } = useAnalyticsContext();

  return useCallback(
    ({ eventPayload }: { eventPayload?: EventPayload } = {}) => {
      enqueueEvent((timestamp) => trackEvent({ ...eventPayload, timestamp }));
    },
    [enqueueEvent, trackEvent],
  ) as T;
}

/**
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
 * @returns A hook that tracks page views
 */
export function useTrackPageView() {
  const { pathname } = useLocation();

  return useTrackEventQueue(
    useCallback(
      (actionPayload: EventPayload = {}) => {
        const { timestamp, ...eventPayload } = actionPayload as PayloadWithTimestamp;
        void window.analytics.page(
          document.title,
          {
            url: pathname,
            ...eventPayload,
            ...getGAProperties(),
          },
          { timestamp },
        );
      },
      [pathname],
    ),
  );
}

export function useTrackInteractionEvent<T extends { label?: string; location?: string; action?: 'clicked' }>(
  eventPayload: T,
) {
  return useTrackEventQueue(_useEventTracking({ eventName: 'interaction', eventPayload: eventPayload as Required<T> }));
}

// Get User Id from Qdrant's user info
export const useAnalyticsIdentification = (userId: string) => {
  const { enqueueEvent } = useAnalyticsContext();

  useMemo(() => {
    enqueueEvent((timestamp) => window.analytics.identify(userId, null, { timestamp }));
  }, [enqueueEvent, userId]);
};

// Getting parameters from url search
function getURLParameter(value?: string | null) {
  return value ? decodeURIComponent((new RegExp('([^;]+?)').exec(value) ?? [null, ''])[1].replace(/\+/g, '%20')) : null;
}

// Getting UTM related url parameters
export const getUTMParameters = (searchParams: URLSearchParams) => {
  const utmParameterList = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'] as const;
  return utmParameterList.reduce(
    (acc, key) => {
      const value = getURLParameter(searchParams.get(key));
      if (value) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<(typeof utmParameterList)[number], string>,
  );
};
