import { Box, Typography } from '@mui/material';
import { getRouteApi, useLocation } from '@tanstack/react-router';
import { useEffect } from 'react';
import { BASE_URL } from '../../utils/constants';

const { useRouteContext } = getRouteApi('/logout');

export function LogoutComponent() {
  const { auth } = useRouteContext();
  const { searchStr } = useLocation();

  useEffect(() => {
    // This request happens inside `useEffect` to avoid blocking the render.
    void auth.logout({ logoutParams: { returnTo: new URL(BASE_URL + searchStr).toString() } });
  }, [auth, searchStr]);

  return auth.isAuthenticated ? (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="20vh">
      <Typography>Signing out ...</Typography>
    </Box>
  ) : null;
}
