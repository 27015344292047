import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns/format';
import { useEffect, useMemo, useState } from 'react';
import { ChatAlt as ChatAltIcon } from '../../../icons/chat-alt';
import { MailOpen as MailOpenIcon } from '../../../icons/mail-open';
import { X as XIcon } from '../../../icons/x';
import { Scrollbar } from '../../Common/Scrollbar';

// This type is a placeholder for the real notification type
type Notification = {
  id: string;
  created_at: number;
  description: string;
  read: boolean;
  type: string;
};

const NotificationItem = ({ data }: { data: Notification }) => {
  switch (data.type) {
    case 'new_feature':
      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              <ChatAltIcon fontSize="small" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Typography variant="subtitle2" sx={{ mr: 0.5 }}>
                  New feature!
                </Typography>
                <Typography variant="body2">{data.description}</Typography>
              </Box>
            }
            secondary={
              <Typography color="textSecondary" variant="caption">
                {format(data.created_at, 'MMM dd, h:mm a')}
              </Typography>
            }
            sx={{ my: 0 }}
          />
        </>
      );
    default:
      return null;
  }
};

export const NotificationsMenu = ({ onUpdateUnread }: { onUpdateUnread: (value: number) => void }) => {
  // todo: when we have a real API, we need to update this component accordingly
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const unread = useMemo(
    () => notifications.reduce((acc, notification) => acc + (notification.read ? 0 : 1), 0),
    [notifications],
  );

  useEffect(() => {
    onUpdateUnread(unread);
  }, [onUpdateUnread, unread]);

  const handleMarkAllAsRead = () => {
    setNotifications((prevState) =>
      prevState.map((notification) => ({
        ...notification,
        read: true,
      })),
    );
  };

  const handleRemoveOne = (notificationId: string) => {
    setNotifications((prevState) => prevState.filter((notification) => notification.id !== notificationId));
  };
  // See https://mui.com/material-ui/react-menu/#account-menu for more details
  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}
      >
        <Typography color="inherit" variant="h6">
          Notifications
        </Typography>
        <Tooltip title="Mark all as read">
          <IconButton onClick={handleMarkAllAsRead} size="small" sx={{ color: 'inherit' }}>
            <MailOpenIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {notifications.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">There are no notifications</Typography>
        </Box>
      ) : (
        <Scrollbar sx={{ maxHeight: 400 }}>
          <List disablePadding={true}>
            {notifications.map((notification) => (
              <ListItem
                divider={true}
                key={notification.id}
                sx={{
                  alignItems: 'flex-start',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                  '& .MuiListItemSecondaryAction-root': {
                    top: '24%',
                  },
                }}
                secondaryAction={
                  <Tooltip title="Remove">
                    <IconButton edge="end" onClick={() => handleRemoveOne(notification.id)} size="small">
                      <XIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Tooltip>
                }
              >
                <NotificationItem data={notification} />
              </ListItem>
            ))}
          </List>
        </Scrollbar>
      )}
    </Box>
  );
};
