import { createRoute, redirect } from '@tanstack/react-router';
import { getAccountUser, Route as AccountRoute } from '.';

export const Route = createRoute({
  getParentRoute: () => AccountRoute,
  path: 'profile',
  async loader({ parentMatchPromise, context: { store } }) {
    await parentMatchPromise; // wait for PathlessAccountRoute to load
    const {
      account: { id: accountId },
    } = await getAccountUser(store);

    throw redirect({ to: '/accounts/$accountId/profile', params: { accountId } });
  },
});
