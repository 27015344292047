import { Forum, GitHub, Twitter, Email } from '@mui/icons-material';
import { IconButton, Link } from '@mui/material';
import { useTrackInteractionEvent } from '../../hooks/use-event-tracking';

export const SocialFooter = ({
  includeEmail,
  color = 'primary',
}: {
  includeEmail?: boolean;
  color?: 'default' | 'primary';
}) => {
  const trackClick = useTrackInteractionEvent({
    location: 'Sidebar',
    action: 'clicked',
  });
  const handleClick = (label: string) => {
    trackClick({ label: `Community: ${label}` });
  };

  return (
    <>
      <IconButton
        component={Link}
        href="https://qdrant.to/discord"
        target="_blank"
        rel="noreferrer"
        color={color}
        title="Qdrant Discord Community"
        aria-label="community"
        onClick={() => handleClick('Discord')}
      >
        <Forum />
      </IconButton>
      <IconButton
        component={Link}
        href="https://github.com/qdrant/qdrant"
        target="_blank"
        rel="noreferrer"
        color={color}
        title="Qdrant Github"
        aria-label="qdrant github"
        onClick={() => handleClick('Github')}
      >
        <GitHub />
      </IconButton>
      <IconButton
        component={Link}
        href="https://qdrant.to/twitter"
        target="_blank"
        rel="noreferrer"
        color={color}
        title="Twitter"
        aria-label="qdrant twitter"
        onClick={() => handleClick('Twitter')}
      >
        <Twitter />
      </IconButton>
      {includeEmail && (
        <IconButton
          component={Link}
          href="mailto:cloud@qdrant.io"
          target={'_blank'}
          rel="noreferrer"
          color={color}
          title="Email"
          aria-label="qdrant email"
          onClick={() => handleClick('Email')}
        >
          <Email />
        </IconButton>
      )}
    </>
  );
};
