export const BASE_URL = window.location.origin;

export const authRedirectUrl = new URL('overview', BASE_URL).toString();

/**
 * This is a simple adblocker check. It's not perfect, but it's good enough for our purposes.
 * @see https://www.detectadblock.com/ for more information.
 */
export const adblocker = !document.getElementById('USoZCRHXaOrQ');

export const WEB_STORAGE_PREFIX = '__qdrant_cloud__::';

export const ELLIPSIS_UNICODE_SYMBOL = '\u2026';

export const TIMES_UNICODE_SYMBOL = '\u00d7';

export const ZERO_WIDTH_SPACE = '\u200b';

export const ASTERISK_OPERATOR = '\u2217';

export const MIDDOT_SYMBOL = '\u00b7';

export const ONE_HOUR_MILLISECONDS = 3_600_000;
