import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '.';
import { clusterApi } from '../../services/clustersApi';
import { iamApi } from '../../services/iamApi';
import { getNonHybridClusters } from '../../utils/cluster-utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Overview',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'overview',
  beforeLoad: async ({ context, params: { accountId } }) => {
    const [clusters, apiKeys] = await Promise.all([
      context.store.dispatch(clusterApi.endpoints.getClustersByAccountId.initiate({ account_id: accountId })),
      context.store.dispatch(iamApi.endpoints.getApiKeysByAccountId.initiate({ account_id: accountId })),
    ]);

    return {
      shouldShowWelcomeStepper: getNonHybridClusters(clusters?.data)?.length === 0 || apiKeys?.data?.length === 0,
    };
  },
}).lazy(() => import(/* webpackChunkName: "overview" */ './overview.lazy').then(({ Route }) => Route));
