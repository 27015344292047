import { createRoute, redirect } from '@tanstack/react-router';
import { Route as AccountRoute, getAccountUser } from '.';

export const Route = createRoute({
  getParentRoute: () => AccountRoute,
  path: 'overview',
  async loader({ context: { store } }) {
    const {
      account: { id: accountId },
    } = await getAccountUser(store);

    throw redirect({ to: '/accounts/$accountId/overview', params: { accountId }, replace: true });
  },
});
