import { useState, createContext, ReactNode, useContext, useMemo, useCallback } from 'react';
import { AlertDialog, GlobalAlertProps } from './GlobalAlertDialog';

type GlobalAlertContextValue = {
  open: (props: GlobalAlertProps) => void;
  close: () => void;
};

const GlobalAlertContext = createContext<GlobalAlertContextValue>({ open: () => undefined, close: () => undefined });

export const useGlobalAlert = () => useContext(GlobalAlertContext);

type GlobalAlertState = (GlobalAlertProps & { isOpen: true }) | (Partial<GlobalAlertProps> & { isOpen: false });

export const GlobalAlertProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<GlobalAlertState>({ isOpen: false });

  const open = useCallback((props: GlobalAlertProps) => {
    setState({ isOpen: true, ...props });
  }, []);

  const close = useCallback(() => {
    setState((state) => ({ ...state, isOpen: false }));
  }, []);

  const value = useMemo(() => ({ open, close }), [open, close]);

  return (
    <>
      <GlobalAlertContext.Provider value={value}>{children}</GlobalAlertContext.Provider>
      <AlertDialog {...state} close={close} />
    </>
  );
};
