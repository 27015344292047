import { captureException, isError } from './error-utils';

/**
 * Captures an error that was thrown by a React ErrorBoundary or React root.
 *
 * @param error The error to capture.
 * @param errorInfo The errorInfo provided by React.
 * @param hint Optional additional data to attach to the Sentry event.
 * @returns the id of the captured Sentry event.
 */
export function captureReactException(
  error: unknown,
  info?: {
    componentStack: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hint?: { mechanism?: { handled: boolean }; tags?: any },
) {
  // If on React version >= 17, create stack trace from componentStack param and links
  // to to the original error using `error.cause` otherwise relies on error param for stacktrace.
  // Linking errors requires the `LinkedErrors` integration be enabled.
  // See: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#native-component-stacks
  //
  // Although `componentDidCatch` is typed to accept an `Error` object, it can also be invoked
  // with non-error objects. This is why we need to check if the error is an error-like object.
  // See: https://github.com/getsentry/sentry-javascript/issues/6167
  if (isError(error) && info?.componentStack) {
    const errorBoundaryError = new Error(error.message);
    errorBoundaryError.name = `React ErrorBoundary ${error.name}`;
    errorBoundaryError.stack = info?.componentStack;

    // Using the `LinkedErrors` integration to link the errors together.
    setCause(error, errorBoundaryError);
  }

  return captureException(error, {
    ...hint,
    captureContext: {
      contexts: { react: { componentStack: info?.componentStack } },
    },
  });
}

/**
 * Recurse through `error.cause` chain to set cause on an error.
 */
function setCause(error: Error, cause: Error) {
  const seenErrors = new WeakSet<Error>();

  function recurse(error: Error, cause?: Error) {
    // If we've already seen the error, there is a recursive loop somewhere in the error's
    // cause chain. Let's just bail out then to prevent a stack overflow.
    if (seenErrors.has(error)) {
      return;
    }
    if (error.cause) {
      seenErrors.add(error);
      return recurse(error.cause as Error, cause);
    }
    error.cause = cause;
  }

  recurse(error, cause);
}
