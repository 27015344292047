import { createRoute } from '@tanstack/react-router';
import { LogoutComponent } from './logout';
import { Route as RootRoute } from '../__root';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Signing out ...',
    },
  ],
  getParentRoute: () => RootRoute,
  path: 'logout',
  component: LogoutComponent,
});
