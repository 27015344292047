import { queryOptions } from '@tanstack/react-query';
import { FromPathParams, QueryData, QueryOptions, queryKey } from './utils';

export const endpoints = {
  paymentInformation: '/accounts/{account_id}/payments/information',
  paymentSession: '/accounts/{account_id}/payments/session/{session_id}',
} as const;

export type PaymentInformationData = QueryData<typeof endpoints.paymentInformation>;
export type PaymentInformationTypeData = PaymentInformationData['type_'];
export type PaymentSessionData = QueryData<typeof endpoints.paymentSession>;

export const getPaymentInformationQuery = (params: FromPathParams<QueryOptions<typeof endpoints.paymentInformation>>) =>
  queryOptions<PaymentInformationData>({
    queryKey: queryKey.get(endpoints.paymentInformation, params),
  });

export const getPaymentSessionQuery = (params: FromPathParams<QueryOptions<typeof endpoints.paymentSession>>) =>
  queryOptions<PaymentSessionData>({
    queryKey: queryKey.get(endpoints.paymentSession, params),
    staleTime: 0,
  });
