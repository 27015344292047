import { useSearch } from '@tanstack/react-router';
import { dequal } from 'dequal/lite';
import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { MAX_DISK_SPACE } from './ClusterDiskSpaceControl';
import { MAX_NODES } from './ClusterNodesControl';
import { Cluster } from '../../../../utils/cluster-utils';
import { objectEntries } from '../../../../utils/func-utils';
import {
  BookingPackages,
  findPackageFromResourceSet,
  getPackageResourceSetByConfiguration,
  getPackageResourceSetById,
  getPackagesResourceScaleByMemoryValue,
} from '../helpers';
import { useQueryStringUpdaterForClusterForm } from '../hooks/use-cluster-create-url';

export type ClusterConfiguration = {
  memory: number;
  cpu: number;
  disk: number;
  additionalDisk: number;
  complimentaryDisk: number;
  nodes: number;
};

type ComputedClusterConfiguration = ClusterConfiguration & {
  bookingPackages: BookingPackages;
  cpuValues: [number] | [number, number] | [number, number, number] | number[];
  diskValues: number[];
};

export type DefaultClusterConfiguration = Omit<ComputedClusterConfiguration, 'cpuValues' | 'diskValues'>;

type ReducerAction<T extends keyof ClusterConfiguration = Exclude<keyof ClusterConfiguration, 'memory'>> =
  | { type: 'init'; value: DefaultClusterConfiguration }
  | { type: 'init'; value: ClusterConfiguration }
  | { type: 'reset'; value: ComputedClusterConfiguration }
  | {
      type: T;
      value: ClusterConfiguration[T];
    };

type UpdaterAction =
  | Exclude<ReducerAction<keyof ClusterConfiguration>, { type: 'init' }>
  | { type: 'reset'; value?: never };

export const initialState: ComputedClusterConfiguration = {
  bookingPackages: [],
  memory: 0,
  cpu: 0,
  cpuValues: [], // CPU values is an array of available CPU values, between one and two CPU values are supported
  disk: 0,
  complimentaryDisk: 0,
  diskValues: [], // Disk values is an array of available disk space values, though only one disk value is supported
  nodes: 1,
  additionalDisk: 0,
};

const Context = createContext<ComputedClusterConfiguration>(initialState);

const DefaultContext = createContext<ComputedClusterConfiguration>(initialState);

const UpdaterContext = createContext<Dispatch<UpdaterAction>>(() => undefined);

export function calculateCpuValue(
  currentCpu: ComputedClusterConfiguration['cpu'],
  cpuValues: ComputedClusterConfiguration['cpuValues'],
) {
  if (cpuValues.length === 0) {
    return currentCpu;
  }
  let cpu = currentCpu;
  const [minCpuValue] = cpuValues;
  const maxCpuValue = cpuValues.at(-1);
  if (!maxCpuValue) {
    cpu = minCpuValue;
  } else if (!cpuValues.includes(cpu)) {
    cpu = Math.min(maxCpuValue, Math.max(minCpuValue, cpu));
  }
  return cpu;
}

/**
 * Reducer for the cluster configuration context. Here's how the actions are used:
 * - 'init' and 'reset' are used to initialize the state with the default values,
 *  since the scales will be recalculated with the incoming bookingPackages.
 * - The other action types are used to update the state with a specific value,
 * while keeping the rest of the state.
 */
export function reducer(state: ComputedClusterConfiguration, { type, value }: ReducerAction) {
  const newState = {
    ...state,
    ...(type === 'init' || type === 'reset' ? value /* cluster configuration value */ : {}),
  };
  // Immutable fields: no need to recalculate
  const { memory, bookingPackages } = newState;
  // Mutable fields: recalculate based on the new value
  let { cpu, cpuValues, disk, diskValues, additionalDisk, complimentaryDisk, nodes } = newState;
  /*
   * The CPU and Disk scales are affected by the memory value and are dependant upon it.
   * The following is used to calculate their scales and adjust their values (if needed)
   * to find the nearest fallback value in their corresponding new scales.
   */
  if (type === 'init') {
    /**
     * IMPORTANT: we assume that the 'init' action always brings in default/initial resource values.
     */
    cpuValues = getPackagesResourceScaleByMemoryValue(bookingPackages, 'cpu', memory);
    cpu = calculateCpuValue(cpu, cpuValues);
    const prevDisk = diskValues.at(0);
    diskValues = getPackagesResourceScaleByMemoryValue(bookingPackages, 'disk', memory);
    const nextDisk = diskValues.at(0);
    if (nextDisk !== undefined && prevDisk !== undefined && prevDisk !== nextDisk) {
      /*
       * Disk values are adjusted to preserve a minimum amount based off of current disk space:
       * - upscaling disk amount may reduce remaining initial complimentary disk,
       * - consuming beyond the available complimentary disk may reduce initial extra disk,
       * - downscaling will increase the extra disk amount to preserve
       * - set the current disk value to the beginning of the new scale.
       */
      if (disk >= nextDisk) {
        /**
         * Downscaling:
         *
         * | base | comp | addi | total | transition |
         * |  16  |  9   |  1   |  26   | ◯ (start)  | (default state)
         * |  8   |  9   |  9   |  26   | ↓          |
         * |  4   |  9   |  13  |  26   | ✕ (finish) |
         */
        additionalDisk += disk - nextDisk;
      } else {
        /**
         * Upscaling:
         *
         * | base | comp | addi | total | transition |
         * |  8   |  7   |  5   |  20   | ◯ (start)  | (default state)
         * |  16  |  0   |  4   |  20   | ↓          |
         * |  32  |  0   |  0   |  32   | ↓          |
         * |  16  |  0   |  4   |  20   | ↓          |
         * |  8   |  7   |  5   |  20   | ✕ (finish) | (default state)
         */
        const totalDisk = disk + complimentaryDisk + additionalDisk;
        complimentaryDisk = Math.max(0, totalDisk - nextDisk - additionalDisk);
        if (nextDisk > prevDisk) {
          additionalDisk = Math.max(0, totalDisk - nextDisk - complimentaryDisk);
        } else {
          additionalDisk = Math.max(0, totalDisk - (nextDisk + complimentaryDisk));
        }
      }
      disk = nextDisk; // type='disk'
    }
  } else {
    switch (type) {
      case 'cpu':
        cpu = value;
        break;
      case 'disk':
        additionalDisk = Math.max(0, value - disk - complimentaryDisk);
        break;
      case 'nodes':
        nodes = value;
        break;
      case 'additionalDisk':
        throw new Error('ClusterConfingurationContext.reducer: additionalDisk cannot be set directly');
      default:
      // do nothing: 'reset' will just return the same state pass in the value
    }
  }
  return {
    memory,
    cpu,
    cpuValues,
    disk,
    diskValues,
    additionalDisk,
    complimentaryDisk,
    nodes,
    bookingPackages,
  };
}

function initializer(initialArg: DefaultClusterConfiguration) {
  return reducer({ ...initialArg, cpuValues: [], diskValues: [] }, { type: 'init', value: initialArg });
}

/**
 * Provider for the cluster configuration context.
 *
 * - The `defaultValues` prop is the default configuration for the cluster, based on the current
 *   booking package for the cluster, or the default booking package (free/paid) for the cluster provider.
 * - The `initialValues` prop is the initial configuration for the cluster, is used to override
 *   the default configuration with a specific configuration (e.g. when editing a cluster).
 */
export function ClusterConfigurationProvider({
  children,
  defaultValues,
}: {
  children: ReactNode;
  defaultValues: DefaultClusterConfiguration;
}) {
  const [state, dispatch] = useReducer(reducer, defaultValues, initializer);
  const defaultStateRef = useRef(state);
  const update = useCallback(
    ({ type, value }: UpdaterAction) => {
      switch (type) {
        case 'reset':
          // Reset to a specific initial config or to the calculated config
          dispatch({ type: 'reset', value: defaultStateRef.current });
          break;
        case 'memory':
          // Reset to the calculated config and override memory with new value
          dispatch({ type: 'init', value: { ...defaultValues, memory: value } });
          break;
        default:
          // Set a specific value
          dispatch({ type, value });
      }
    },
    [defaultValues],
  );
  // Sync the state with the default values and the URL query params
  useInitialValues(defaultStateRef, defaultValues, dispatch);
  // Update url query string to match state without triggering navigation
  useQueryStringUpdaterForClusterForm(
    'pid',
    useMemo(
      () => findPackageFromResourceSet(state.bookingPackages, [state.memory, state.cpu, state.disk])?.id ?? null,
      [state.bookingPackages, state.cpu, state.disk, state.memory],
    ),
  );
  useQueryStringUpdaterForClusterForm('nodes', state.nodes);
  useQueryStringUpdaterForClusterForm('disk', state.additionalDisk, { timeout: 100 });

  return (
    <Context.Provider value={state}>
      <DefaultContext.Provider value={defaultStateRef.current}>
        <UpdaterContext.Provider value={update}>{children}</UpdaterContext.Provider>
      </DefaultContext.Provider>
    </Context.Provider>
  );
}

/**
 * Hook to re-initialize the cluster configuration context with the default values and the provided URL query params.
 */
export function useInitialValues(
  defaultStateRef: MutableRefObject<ComputedClusterConfiguration>,
  defaultValues: DefaultClusterConfiguration,
  dispatch: Dispatch<{ type: 'reset'; value: ComputedClusterConfiguration }>,
) {
  const {
    pid: preSelectedPidParam,
    nodes: preSelectedNodesParam,
    disk: preSelectedAdditionalDiskParam,
  } = useSearch({
    strict: false,
    select: ({ pid, nodes, disk }) => ({
      pid,
      nodes,
      disk,
    }),
  });

  useEffect(() => {
    // Reset the default state when defaultValues or URL query params change,
    // just like the initializer does the on the first render
    const { cpuValues: _cpuValues, diskValues: _diskValues, ...prevDefaultValues } = defaultStateRef.current;
    if (!dequal(prevDefaultValues, defaultValues)) {
      // Update the reference to the default values so that prevDefaultValues works each time
      defaultStateRef.current = initializer(defaultValues);
      dispatch({ type: 'reset', value: defaultStateRef.current });
    }
    let { ...initialValues } = defaultStateRef.current;
    // Maybe override the configuration with a given package resource set
    if (preSelectedPidParam) {
      const packageValues = getPackageResourceSetById(initialValues.bookingPackages, preSelectedPidParam);
      if (packageValues) {
        initialValues = initializer({ ...initialValues, memory: packageValues.memory, cpu: packageValues.cpu });
      }
    }
    // Maybe override the configuration with a given number of nodes
    if (preSelectedNodesParam !== undefined) {
      initialValues = {
        ...initialValues,
        nodes: Math.min(Math.max(1, preSelectedNodesParam), MAX_NODES),
      };
    }
    // Maybe override the configuration with a given additional disk space
    if (preSelectedAdditionalDiskParam !== undefined) {
      initialValues = {
        ...initialValues,
        additionalDisk: Math.min(
          Math.max(initialValues.additionalDisk, preSelectedAdditionalDiskParam),
          MAX_DISK_SPACE - initialValues.disk,
        ),
      };
    }
    if (!dequal(defaultStateRef.current, initialValues)) {
      // Update store with the new initial values changed by the URL query params
      dispatch({ type: 'reset', value: initialValues });
    }
  }, [
    defaultValues,
    defaultStateRef,
    dispatch,
    preSelectedPidParam,
    preSelectedNodesParam,
    preSelectedAdditionalDiskParam,
  ]);
}

// Hooks to access the cluster configuration context
export const useClusterConfig = () => useContext(Context);
export const useDefaultClusterConfig = () => useContext(DefaultContext);
export const useClusterConfigUpdater = () => useContext(UpdaterContext);

// Returns fields diffing from the original cluster configuration
export const useConfigUpdates = (
  cluster: Cluster,
): ({ type: keyof ClusterConfiguration; value: number } | undefined)[] => {
  const initialConfig = getPackageResourceSetByConfiguration(cluster);
  const updatedConfig = useClusterConfig();

  return objectEntries(initialConfig)
    .filter(([key, value]) => updatedConfig[key] !== value)
    .map(([type, value]) => ({ type, value }));
};
