import { createRouteMask } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '../routes/(account-dashboard)';
import { Route as BackupsRoute } from '../routes/(account-dashboard)/backups';
import { Route as BillingRoute } from '../routes/(account-dashboard)/billing';
import { Route as ProgrammaticAccessRoute } from '../routes/(account-dashboard)/cloud-access/programmatic-access';
import { Route as RolesRoute } from '../routes/(account-dashboard)/cloud-access/roles';
import { Route as RoleIdRoute } from '../routes/(account-dashboard)/cloud-access/roles.$roleId';
import { Route as RoleIdPermissions } from '../routes/(account-dashboard)/cloud-access/roles.$roleId.permissions';
import { Route as RoleIdUsers } from '../routes/(account-dashboard)/cloud-access/roles.$roleId.users';
import { Route as AllUsersRoute } from '../routes/(account-dashboard)/cloud-access/roles.all-users';
import { Route as CloudAccessRoute } from '../routes/(account-dashboard)/cloud-access_';
import { Route as ClustersIndexRoute } from '../routes/(account-dashboard)/clusters';
import { Route as ClusterIdRoute } from '../routes/(account-dashboard)/clusters/$clusterId';
import { Route as ClusterIdIndexRoute } from '../routes/(account-dashboard)/clusters/$clusterId.index';
import { Route as ClusterIdScaleRoute } from '../routes/(account-dashboard)/clusters/$clusterId.scale';
import { Route as ClusterCreateRoute } from '../routes/(account-dashboard)/clusters/create';
import { Route as ClustersRoute } from '../routes/(account-dashboard)/clusters_';
import { Route as DataAccessRoute } from '../routes/(account-dashboard)/data-access';
import { Route as HybridCloudRoute } from '../routes/(account-dashboard)/hybrid-cloud';
import { Route as HybridCloudEnvironmentsIndexRoute } from '../routes/(account-dashboard)/hybrid-cloud-enviroments';
import { Route as HybridCloudEnvironmentRoute } from '../routes/(account-dashboard)/hybrid-cloud-enviroments/$hybridCloudEnvId';
import { Route as HybridCloudEnvironmentEditRoute } from '../routes/(account-dashboard)/hybrid-cloud-enviroments/$hybridCloudEnvId.edit';
import { Route as HybridCloudEnvironmentIndexRoute } from '../routes/(account-dashboard)/hybrid-cloud-enviroments/$hybridCloudEnvId.index';
import { Route as HybridCloudEnvironmentsCreateRoute } from '../routes/(account-dashboard)/hybrid-cloud-enviroments/create';
import { Route as HybridCloudEnvironmentsOnboardingRoute } from '../routes/(account-dashboard)/hybrid-cloud-enviroments/onboarding';
import { Route as HybridCloudEnvironmentsRoute } from '../routes/(account-dashboard)/hybrid-cloud-environments_';
import { Route as AccountOverviewRoute } from '../routes/(account-dashboard)/overview';
import { Route as AccountProfileRoute } from '../routes/(account-dashboard)/profile';
import { Route as RootRoute } from '../routes/__root';
import { Route as AuthenticatedRoute } from '../routes/_authenticated';
import { Route as AccountRoute } from '../routes/_authenticated/_account';
import { Route as OverviewRoute } from '../routes/_authenticated/_account/overview';
import { Route as ProfileRoute } from '../routes/_authenticated/_account/profile';
import { Route as AccountIdRoute } from '../routes/_authenticated/accounts_/$accountId';
import { Route as CalculatorRoute } from '../routes/calculator_';
import { Route as IndexRoute } from '../routes/index';
import { Route as LegalRoute } from '../routes/legal_';
import { Route as LoginRoute } from '../routes/login_';
import { Route as LogoutRoute } from '../routes/logout_';

export const routeTree = RootRoute.addChildren([
  IndexRoute,
  LoginRoute,
  LogoutRoute,
  CalculatorRoute,
  LegalRoute,
  AuthenticatedRoute.addChildren([
    AccountRoute.addChildren([OverviewRoute, ProfileRoute]),
    AccountIdRoute.addChildren([
      AccountDashboardRoute.addChildren([
        AccountOverviewRoute,
        AccountProfileRoute,
        ClustersRoute.addChildren([
          ClustersIndexRoute,
          ClusterCreateRoute,
          ClusterIdRoute.addChildren([ClusterIdIndexRoute, ClusterIdScaleRoute]),
        ]),
        HybridCloudRoute,
        HybridCloudEnvironmentsRoute.addChildren([
          HybridCloudEnvironmentsIndexRoute,
          HybridCloudEnvironmentsCreateRoute,
          HybridCloudEnvironmentsOnboardingRoute,
          HybridCloudEnvironmentRoute.addChildren([HybridCloudEnvironmentIndexRoute, HybridCloudEnvironmentEditRoute]),
        ]),
        BackupsRoute,
        CloudAccessRoute.addChildren([
          RolesRoute.addChildren([AllUsersRoute, RoleIdRoute.addChildren([RoleIdUsers, RoleIdPermissions])]),
          ProgrammaticAccessRoute,
        ]),
        DataAccessRoute,
        BillingRoute,
      ]),
    ]),
  ]),
]);

export const ProfileRouteMask = createRouteMask({
  routeTree,
  from: '/accounts/$accountId/profile',
  to: '/profile',
  params: true,
});
