import { createRoute, redirect } from '@tanstack/react-router';
import { featureFlagsQuery } from '../../../api/services/core';
import { getPaymentInformationQuery } from '../../../api/services/payment';
import { enqueueSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { clusterCreateSearchSchema, clusterSetupSearchSchema } from '../../../router/utils';
import { clusterApi } from '../../../services/clustersApi';
import { AccountPrivilege } from '../../../utils/constants/access-control';
import { RootRouteContext } from '../../__root';
import { getAccountUser } from '../../_authenticated/_account';
import { Route as ClustersRoute } from '../clusters_';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Create cluster',
    },
  ],
  getParentRoute: () => ClustersRoute,
  path: 'create',
  validateSearch: (search) => clusterCreateSearchSchema.and(clusterSetupSearchSchema).parse(search),
  async beforeLoad({ context: { store, queryClient }, params }) {
    const [featureFlags, accountUser, clusters] = await Promise.all([
      queryClient.ensureQueryData(featureFlagsQuery),
      getAccountUser(store, { account_id: params.accountId }),
      getClustersByAccountId(store, params.accountId),
    ]);

    if (!featureFlags.cluster_creation_enabled) {
      enqueueSnackbar({ message: 'Cluster creation is currently disabled.', variant: 'warning' });
      throw redirect({ to: '/accounts/$accountId/clusters', params });
    }

    const accountPrivileges = accountUser.account.privileges;

    if (accountPrivileges) {
      if (clusters.length >= getMaxAllowedClusters(accountPrivileges)) {
        enqueueSnackbar({
          message: (
            <>
              You have reached the maximum number of clusters allowed for your account.
              <br />
              Please contact support if you wish to increase your cluster limit.
            </>
          ),
          variant: 'warning',
        });
        throw redirect({ to: '/accounts/$accountId/clusters', params });
      }
    }

    return { paymentInformationQueryOptions: getPaymentInformationQuery({ account_id: params.accountId }) };
  },
}).lazy(() => import(/* webpackChunkName: "cluster-create" */ './create.lazy').then(({ Route }) => Route));

export function getMaxAllowedClusters(availablePrivileges: AccountPrivilege[]) {
  const defaultMaxClusters = 10;
  const requiredPrivileges = ['MAX_NUM_CLUSTERS_20', 'MAX_NUM_CLUSTERS_30', 'MAX_NUM_CLUSTERS_50'] as const;
  const privilegeToMaxClusters = new Map<AccountPrivilege, number>([
    ['MAX_NUM_CLUSTERS_20', 20],
    ['MAX_NUM_CLUSTERS_30', 30],
    ['MAX_NUM_CLUSTERS_50', 50],
  ]);

  return requiredPrivileges.reduce(
    (acc, val) => (availablePrivileges.includes(val) ? Math.max(acc, privilegeToMaxClusters.get(val) ?? 0) : acc),
    defaultMaxClusters,
  );
}

export function getClustersByAccountId(store: RootRouteContext['store'], accountId: string) {
  return store.dispatch(clusterApi.endpoints.getClustersByAccountId.initiate({ account_id: accountId })).unwrap();
}
