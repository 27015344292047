import { createRoute, redirect } from '@tanstack/react-router';
import { Route as AuthenticatedRoute } from '..';
import { isFetchApiError, parseFetchError } from '../../../services/helpers';
import { iamApi } from '../../../services/iamApi';
import { isAccountMissingError } from '../../../utils/account-utils';
import { captureException } from '../../../utils/error-utils';
import { AuthContext, RootRouteContext } from '../../__root';

/**
 * Parent route for sub-routes that require the account user data, but DO NOT HAVE the param in the URL.
 */
export const Route = createRoute({
  getParentRoute: () => AuthenticatedRoute,
  id: 'account',
  /**
   * Load the user information, or create a new user if it doesn't exist.
   * Fetch errors are handled in the parent route's error boundary.
   */
  async beforeLoad({ context: { auth, store } }) {
    if (await fetchAccountUser({ store })) {
      return;
    }
    await createAccountUser({ auth, store });
  },
});

/**
 * This async function gets the account user data from the API.
 * If the account user data is not available, the user is redirected to the logout page.
 * If the account id is not part of the account data, the user is redirected to the default account.
 * The forceRefetch option is used to bypass the cache and force a network request, since
 * cache invalidation is performed with 'router.invalidate()' for the endpoint, instead of using RTK's tag invalidation.
 */
export async function fetchAccountUser({ store, accountId }: { store: RootRouteContext['store']; accountId?: string }) {
  try {
    return await store.dispatch(iamApi.endpoints.getUserInfo.initiate({ account_id: accountId })).unwrap();
    // We're done! The userInfo is available. Pass the current account to the router data loader.
  } catch (err) {
    // The error 'AccountMissingError' occurs if the payload is missing for the given $accountId URL param.
    if (isAccountMissingError(err)) {
      // Redirect to the default account in the payload.
      if (err.message) {
        throw redirect({
          to: '/accounts/$accountId/overview',
          params: { accountId: err.message },
        });
      }
      // Sanity check: throw if the default account is also missing.
      throw new Error('Default account is missing');
    }
    const fetchError = parseFetchError(err);
    if (isFetchApiError(fetchError)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (fetchError.code === 'E1041') {
        // User was deleted, this can happen if the user deleted the account.
        throw redirect({ to: '/logout' });
      }
      if (fetchError.originalStatus === 404) {
        // Account user data not available, create the account.
        return;
      }
    }
    throw fetchError;
  }
}

export async function getAccountUser(store: RootRouteContext['store'], args?: { account_id: string }) {
  try {
    return await store.dispatch(iamApi.endpoints.getUserInfo.initiate(args)).unwrap();
  } catch (err) {
    throw parseFetchError(err);
  }
}

/**
 * This async function creates an account for the user if it doesn't exist.
 * The user object is expected to have the 'name' and 'sub' properties.
 * If the account already exists, the user is redirected to the logout page.
 */
export async function createAccountUser({ store, auth }: { store: RootRouteContext['store']; auth: AuthContext }) {
  const { user } = auth;
  if (!(user.name && user.sub)) {
    captureException(new Error('Properties `name` or `sub` missing in the Auth0 user object'), {
      level: 'fatal',
      tags: { context: 'auth', action: 'create-account', route: window.location.toString() },
    });
    throw redirect({ to: '/logout' });
  }
  try {
    await store
      .dispatch(iamApi.endpoints.createAccount.initiate({ name: `${user.name} - Base Account`, owner_id: user.sub }))
      .unwrap();
  } catch (err) {
    const fetchError = parseFetchError(err);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (isFetchApiError(fetchError) && fetchError.code === 'E1001') {
      // Account already exists, this can happen if the user deleted the account.
      throw redirect({ to: '/logout' });
    }
    throw fetchError;
  }
}
