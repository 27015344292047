import { createRoute } from '@tanstack/react-router';
import { Route as RoleDetailsRoute } from './roles.$roleId';
import { getAccountPermissionsQuery } from '../../../api/services/access-management';

export type Permission = {
  title: string;
  description: string;
  permissions: {
    name: string;
    key: string;
  }[];
};

export const Route = createRoute({
  getParentRoute: () => RoleDetailsRoute,
  path: 'permissions',
  beforeLoad: ({ params: { accountId } }) => ({
    permissionsQueryOptions: getAccountPermissionsQuery({ account_id: accountId }),
  }),
}).lazy(() =>
  import(/* webpackChunkName: "role-id-permissions" */ './roles.$roleId.permissions.lazy').then(({ Route }) => Route),
);
