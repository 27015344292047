import { User } from '@auth0/auth0-react';

const tokenNamespace = 'https://cloud.qdrant.io';

/**
 * Checks if the user has the QdrantDeveloper Role.
 */
export const hasDeveloperRole = (user?: User): boolean =>
  (user != null && getUserClaim(user, 'role')?.includes('QdrantDeveloper')) ?? false;

/**
 * Get a claim from the user object.
 */
export function getUserClaim<T extends 'role' | 'email' | 'name'>(
  user: User,
  claim: T,
): T extends 'role' ? string[] | undefined : string | undefined {
  const key = `${tokenNamespace}/${claim}`;
  if (key in user) {
    return user[key]; // eslint-disable-line @typescript-eslint/no-unsafe-return
  }
}
