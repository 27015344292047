import { createRoute, redirect } from '@tanstack/react-router';
import { Route as ClusterIdRoute } from './$clusterId';
import { ClusterScalableData } from '../../../api/services/cluster';
import { FeatureFlagsData, featureFlagsQuery } from '../../../api/services/core';
import { getPaymentInformationQuery } from '../../../api/services/payment';
import { getClusterScalableError } from '../../../components/Clusters/hooks/useGetClusterScalable';
import { enqueueSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { clusterCreateSearchSchema, clusterSetupSearchSchema } from '../../../router/utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Scale cluster',
    },
  ],
  getParentRoute: () => ClusterIdRoute,
  path: 'scale',
  validateSearch: (search) => clusterCreateSearchSchema.and(clusterSetupSearchSchema).parse(search),
  beforeLoad: ({ params: { accountId }, context: { clusterScalableQueryOptions } }) => ({
    paymentInformationQueryOptions: getPaymentInformationQuery({ account_id: accountId }),
    clusterScalableQueryOptions: { ...clusterScalableQueryOptions },
  }),
  async loader({ params, context: { queryClient, clusterScalableQueryOptions } }) {
    await ensureClusterCreationEnabled(queryClient.ensureQueryData(featureFlagsQuery), params);
    await ensureClusterScalable(queryClient.fetchQuery(clusterScalableQueryOptions), params);
  },
}).lazy(() => import(/* webpackChunkName: "cluster-id-scale" */ './$clusterId.scale.lazy').then(({ Route }) => Route));

async function ensureClusterCreationEnabled(
  featureFlags: Promise<FeatureFlagsData>,
  params: typeof Route.types.allParams,
) {
  const { cluster_creation_enabled: enabled } = await featureFlags;

  if (!enabled) {
    enqueueSnackbar({ message: 'Cluster creation is currently disabled', variant: 'warning' });

    throw redirect({ to: '/accounts/$accountId/clusters', params });
  }
}

async function ensureClusterScalable(
  clusterScalable: Promise<ClusterScalableData>,
  params: typeof Route.types.allParams,
) {
  try {
    await clusterScalable;
  } catch (err) {
    enqueueSnackbar({ message: getClusterScalableError(err), variant: 'warning' });

    throw redirect({ to: '/accounts/$accountId/clusters/$clusterId', params });
  }
}
