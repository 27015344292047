import * as Sentry from '@sentry/react';
import { useLocation, ErrorComponentProps } from '@tanstack/react-router';
import { ComponentType, useCallback } from 'react';
import { DefaultErrorComponent } from '../components/Common/DefaultErrorComponent';
import { isModuleNotFoundError } from '../utils/error-utils';
import { captureReactException } from '../utils/react-error-utils';

export const createRouteErrorComponent =
  (ErrorComponent?: ComponentType<ErrorComponentProps>) =>
  ({ error, info, reset }: ErrorComponentProps) => {
    let _eventId = '';
    if (!isModuleNotFoundError(error)) {
      /**
       * Note: starting React 19, there will be specific error hooks to capture React errors.
       * @see https://docs.sentry.io/platforms/javascript/guides/react/#react-19-error-reporting
       */
      _eventId = captureReactException(error, info, { mechanism: { handled: true } });
    }
    const { pathname } = useLocation();
    const moduleNotFoundErrorHandler = useCallback(async () => {
      Sentry.addBreadcrumb({
        category: 'navigation',
        message: 'Reloading due to module not found error',
        data: { from: pathname, to: pathname },
      });
      captureReactException(error, info, {
        mechanism: { handled: true },
        tags: { webpack: 'ChunkLoadError' },
      });
      /**
       * The flush method optionally takes a timeout in milliseconds and returns a promise that resolves
       * when all pending events are flushed, or the timeout kicks in.
       * @see https://docs.sentry.io/platforms/javascript/guides/cloudflare/configuration/draining/
       */
      await Sentry.flush(2000);
      window.location.reload();
    }, [error, info, pathname]);

    return (
      <DefaultErrorComponent error={error} onModuleNotFoundError={moduleNotFoundErrorHandler}>
        {ErrorComponent ? <ErrorComponent error={error} info={info} reset={reset} /> : null}
      </DefaultErrorComponent>
    );
  };
