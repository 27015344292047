import { createRoute } from '@tanstack/react-router';
import { Route as CloudAccessRolesRoute } from './roles';
import { getInvitesByAccountId } from '../../../api/services/user';
import { getAccountUser } from '../../_authenticated/_account';

export const Route = createRoute({
  getParentRoute: () => CloudAccessRolesRoute,
  path: 'all-users',
  beforeLoad: async ({ context: { store }, params: { accountId } }) => {
    const {
      account: { permissions = [], privileges = [] },
    } = await getAccountUser(store, { account_id: accountId });
    const hasCloudRbacPrivilege = privileges.includes('CLOUD_RBAC');
    const hasInvitesReadPermission = permissions.includes('read:invites');

    if (!hasCloudRbacPrivilege || hasInvitesReadPermission) {
      return { invitesQueryOption: getInvitesByAccountId({ account_id: accountId }) };
    }
    return { invitesQueryOption: null };
  },
  loader: async ({ context: { queryClient, invitesQueryOption } }) => {
    if (invitesQueryOption) {
      await queryClient.fetchQuery(invitesQueryOption);
    }
  },
}).lazy(() => import(/* webpackChunkName: "all-users" */ './roles.all-users.lazy').then(({ Route }) => Route));
