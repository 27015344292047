import { createRoute } from '@tanstack/react-router';
import { Route as HybridCloudEnvironmentRoute } from './$hybridCloudEnvId';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Hybrid Cloud Environment',
    },
  ],
  getParentRoute: () => HybridCloudEnvironmentRoute,
  path: '/',
}).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-env-index" */ './$hybridCloudEnvId.index.lazy').then(({ Route }) => Route),
);
