import { Button } from '@mui/material';
// Restricted import only for outside this component and our custom hook (use-qdrant-snackbar).
// eslint-disable-next-line no-restricted-imports
import { SnackbarKey, useSnackbar } from 'notistack';

export function SnackbarDismissButton(snackbarKey: SnackbarKey) {
  const { closeSnackbar } = useSnackbar();

  return (
    <Button onClick={() => closeSnackbar(snackbarKey)} sx={{ color: '#FFF' }}>
      Close
    </Button>
  );
}
