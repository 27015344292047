import { ComponentSchema } from './api-schema-utils';

export type BookingPackageOutput = ComponentSchema<'PackageOut-Output'>;
export type ResourceConfigurationInput = ComponentSchema<'ResourceConfiguration-Input'>;
export type ResourceType = ComponentSchema<'ResourceType'>;
export type ClusterResourcesSummary = ComponentSchema<'ClusterResourcesSummary'>;
export type Currency = ComponentSchema<'Currency'>;
export type ClusterConfigurationOutput = ComponentSchema<'ClusterConfigurationOut'>;

type BookingStatus = ComponentSchema<'BookingStatus'>;

/**
 * BookingStatus
 * @description
 * PLANNED - 1: The booking is planned, but not yet active
 * ACTIVE - 2: The booking is active
 * DEACTIVATED - 3: The booking is deactivated
 */
export const BOOKING_STATUS = { PLANNED: 1, ACTIVE: 2, DEACTIVATED: 3 } as const satisfies Record<
  string,
  BookingStatus
>;
