import { DefaultError, MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { Middleware, ParamsOption } from 'openapi-fetch';
import { PathsWithMethod } from 'openapi-typescript-helpers';
import { ApiClientError, client, isJsonApiClientError, isApiClientError } from './client';
import { paths } from '../api-schema';
import { enqueueSnackbar } from '../hooks/use-qdrant-snackbar';
import { BASE_URL, ONE_HOUR_MILLISECONDS } from '../utils/constants';

declare module '@tanstack/react-query' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Register {
    defaultError: ApiClientError | TypeError | DOMException;
  }
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // This function will be called if some query encounters an error.
    onError: defaultErrorHandler,
  }),
  mutationCache: new MutationCache({
    /*
     * This function will be called if some mutation encounters an error.
     * If you return a Promise from it, it will be awaited.
     */
    onError(error) {
      defaultErrorHandler(error);
      /**
       * - network errors: failure to connect to the server which can be caused by several reasons,
       *   such as slow network and timeout, for example.
       * - CORS errors: when a domain is not authorised to obtain resources from a different domain.
       * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/fetch#exceptions
       */
      if (error instanceof TypeError) {
        // Display a snackbar notification for mutation errors only; query errors must be handled by catch boundaries,
        // so that they are displayed as a fallback for the fetching component instead.
        enqueueSnackbar(error.message, { variant: 'error', preventDuplicate: true });
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: shouldRetry,
      staleTime: ONE_HOUR_MILLISECONDS / 2,
      queryFn: async ({ queryKey, signal }) => {
        type Path = PathsWithMethod<paths, 'get'>;
        type Params = ParamsOption<paths[Path]>;

        /**
         * Default queryFn implementation for all GET requests. Global onError handlers implemented above
         * handle fetch errors and unauthorized responses.
         *
         * When the promise resolves, 'data' can be assumed to be there and returned directly.
         * API errors are throw as as 'ApiClientError' automatically via middleware (see client.ts)
         */
        const { data } = await client.GET(queryKey[0] as Path, {
          signal,
          params: { ...(queryKey[1] as Params)?.params, ...(queryKey[2] as Params)?.params },
        });
        return data;
      },
    },
    mutations: {
      retry: shouldRetry,
    },
  },
});

function shouldRetry(failureCount: number, error: DefaultError) {
  if (isUnauthorizedError(error)) {
    return false;
  }
  return failureCount < 1;
}

function defaultErrorHandler(error: DefaultError) {
  /**
   * API unauthorized response errors are treated as a logout event.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
   */
  if (isUnauthorizedError(error)) {
    const logoutUrl = new URL('logout', BASE_URL);

    if (isJsonApiClientError(error)) {
      const { error: e } = error;
      try {
        logoutUrl.search = new URL(e.detail as string).search;
      } catch {
        // noop
      }
    } else {
      logoutUrl.searchParams.set('aerr', 'Login required');
    }
    // Note: instead of router.buildAndCommitLocation() which provides proper router typing,
    // we have to redirect the traditional way because of a module dependency circular dependency.
    window.location.assign(logoutUrl);
  }
}

function isUnauthorizedError(error: DefaultError) {
  return isApiClientError(error) && error.originalStatus === 401;
}

/**
 * This middleware appends 'X-Qd-Version' header to all requests,
 * so that we know which UI App version issued the request to the API
 */
const QdVersionRegex = /(?:[0-9]{1,5}\.[0-9]{1,5}\.[0-9]{1,5})/;
const versionHeaderMiddleware: Middleware = {
  async onRequest({ request }) {
    const { tag } = await queryClient.ensureQueryData<{ tag: string }>({
      queryKey: ['settings'],
    });

    if (QdVersionRegex.test(tag)) {
      request.headers.set('X-Qd-Version', tag);
    }

    return request;
  },
};

client.use(versionHeaderMiddleware);
