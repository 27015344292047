import { createSvgIcon } from '@mui/material/utils';

export const HybridCloudIconFilled = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5250_2199)">
      <path
        d="M22 11C22 11.16 21.99 11.31 21.97 11.47C21.75 13.47 19.91 14.9 17.9 14.9H15.94L16.42 14.42C16.63 14.21 16.78 13.96 16.85 13.76C16.88 13.7 16.92 13.59 16.95 13.44C17.01 13.15 17.01 12.85 16.95 12.57C16.92 12.42 16.88 12.32 16.87 12.29C16.78 12.04 16.63 11.8 16.42 11.58L14.42 9.58C14.04 9.21 13.54 9 13 9C12.46 9 11.96 9.21 11.58 9.58C11.23 9.94 11.02 10.42 11 10.93C11 10.97 10.97 11 10.93 11H7.13C6.2 11 5.31 11.57 5.07 12.47C4.81 13.44 5.29 14.36 6.07 14.77C6.14 14.8 6.11 14.9 6.04 14.9H4.07C2.85 14.9 1.62 14.43 0.86 13.48C0.32 12.8 0 11.95 0 11C0 9.12 1.28 7.53 2.95 7.11C2.98 7.1 3 7.08 3 7.05C3.23 3.17 6.52 0 10.5 0C14.48 0 17.67 3.07 18 6.94C18 6.97 18.03 7 18.06 7C20.23 7.03 22 8.82 22 11Z"
        fill="currentColor"
      />
      <path
        d="M16 13.0001C16 13.8801 13.71 15.7101 13.71 15.7101C13.51 15.9001 13.26 16.0001 13 16.0001C12.74 16.0001 12.49 15.9001 12.29 15.7101C12.07 15.4901 11.97 15.1901 12.01 14.9001C12.03 14.6801 12.12 14.4601 12.29 14.2901L12.58 14.0001H7C6.45 14.0001 6 13.5501 6 13.0001C6 12.4501 6.45 12.0001 7 12.0001H12.58L12.29 11.7101C11.9 11.3201 11.9 10.6801 12.29 10.2901C12.68 9.90006 13.32 9.90006 13.71 10.2901C13.71 10.2901 16 12.1301 16 13.0001Z"
        fill="currentColor"
      />
      <path
        d="M15.9999 19.0001C15.9999 19.5501 15.5499 20.0001 14.9999 20.0001H9.41994L9.70994 20.2901C10.0999 20.6801 10.0999 21.3201 9.70994 21.7101C9.50994 21.9001 9.25994 22.0001 8.99994 22.0001C8.73994 22.0001 8.48994 21.9001 8.28994 21.7101L6.28994 19.7101C6.19994 19.6201 6.11994 19.5101 6.07994 19.3901C6.05994 19.3401 6.03994 19.3001 6.03994 19.2501C5.98994 19.0901 5.98994 18.9101 6.03994 18.7501C6.03994 18.7001 6.05994 18.6601 6.07994 18.6101C6.11994 18.4901 6.19994 18.3801 6.28994 18.2901L8.28994 16.2901C8.67994 15.9001 9.31994 15.9001 9.70994 16.2901C10.0999 16.6801 10.0999 17.3201 9.70994 17.7101L9.41994 18.0001H14.9999C15.5499 18.0001 15.9999 18.4501 15.9999 19.0001Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5250_2199">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'HybridCloudIconFilled',
);
