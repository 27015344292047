import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '.';
import { hybridCloudSearchSchema } from '../../router/utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Hybrid Cloud',
    },
  ],
  staticData: {
    privileges: ['PRIVATE_REGION'],
  },
  getParentRoute: () => AccountDashboardRoute,
  path: 'hybrid-cloud',
  validateSearch: (search) => hybridCloudSearchSchema.parse(search),
}).lazy(() => import(/* webpackChunkName: "hybrid-cloud" */ './hybrid-cloud.lazy').then(({ Route }) => Route));
