import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const tokenSlice = createSlice({
  name: 'auth',
  initialState: '',
  reducers: {
    setToken: (_, action: PayloadAction<string>) => action.payload,
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = tokenSlice;
// Extract and export each action creator by name
export const { setToken } = actions;
// Export the reducer, either as a default or named export
export { reducer as token };
