const pxToRem = (size) => `${size / 16}rem`;

export const buttonRound = (theme) => ({
  '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
  color: theme.palette.white,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: pxToRem(40),
  fontSize: pxToRem(18),
  overflowX: 'hidden',

  '&:before': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000)',
    transform: 'scale(0, 1)',
    transformOrigin: 'top right',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },

  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },

  '&:hover:before': {
    transform: 'scale(1, 1)',
    transformOrigin: 'bottom left',
  },

  '&:disabled': {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.grey[600],
  },
});

export const buttonText = () => ({
  position: 'relative',
  zIndex: 6,
  fontSize: pxToRem(18),
});

/**
 * returns a function that a style object containing filter property with invert and grayscale filters
 * used on providers images to invert them when the theme is dark
 * and grayscale them when they are inside a disabled button
 * @param theme
 * @param isDisabled - if true, the image will be grayscale
 * @returns {{filter: (`${string} ${string}`|string)}}
 */
export const imageFilters = (theme, isDisabled = false, themed = true) => {
  const invertFilter = themed && theme.palette.mode === 'dark' ? 'invert(1)' : '';
  const greyScaleFilter = isDisabled ? 'grayscale(1)' : '';
  const filter = `${invertFilter} ${greyScaleFilter}`;

  return { filter };
};

export const customScrollBar = (theme) => {
  const scrollbarThumbColor = theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.neutral[500];
  const scrollbarTrackColor = theme.palette.mode === 'dark' ? theme.palette.background.paper : 'transparent';

  return {
    scrollbarWidth: 'thin',
    scrollbarHeight: 'thin',
    scrollbarColor: `${scrollbarThumbColor} ${scrollbarTrackColor}`,
    '&::-webkit-scrollbar': {
      display: 'none',
      width: '0.4rem',
      height: '0.4rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: scrollbarTrackColor,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: scrollbarThumbColor,
      borderRadius: '0.4rem',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        display: 'block',
      },

      '@media (hover: none)': {
        scrollbarWidth: 'thin',
        scrollbarHeight: 'thin',
        scrollbarColor: `${scrollbarThumbColor} ${scrollbarTrackColor}`,
        '&::-webkit-scrollbar': {
          display: 'block',
        },
      },
    },
  };
};
