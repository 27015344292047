import { createRoute } from '@tanstack/react-router';
import { getClusterScalableQuery } from '../../../api/services/cluster';
import { Route as ClustersRoute } from '../clusters_';

export const Route = createRoute({
  getParentRoute: () => ClustersRoute,
  path: '$clusterId',
  beforeLoad: ({ params }) => ({
    clusterScalableQueryOptions: getClusterScalableQuery({
      account_id: params.accountId,
      cluster_id: params.clusterId,
    }),
  }),
}).lazy(() => import(/* webpackChunkName: "cluster-id" */ './$clusterId.lazy').then(({ Route }) => Route));
