import { createRoute } from '@tanstack/react-router';
import { Route as AccessManagementTabPanelRoute } from './roles';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => AccessManagementTabPanelRoute,
    path: '$roleId',
    staticData: {
      privileges: ['CLOUD_RBAC'],
    },
  }),
).lazy(() => import(/* webpackChunkName: "role-id" */ './roles.$roleId.lazy').then(({ Route }) => Route));
