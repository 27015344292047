import { onlineManager } from '@tanstack/react-query';
import { ReactNode } from '@tanstack/react-router';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';

const DEFAULT_IS_ONLINE = true;
const Context = createContext(DEFAULT_IS_ONLINE);

export function NetworkProvider({ children }: { children: ReactNode }) {
  const [isOnline, setIsOnline] = useState(DEFAULT_IS_ONLINE);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(
    () =>
      onlineManager.subscribe((isOnline) => {
        setIsOnline(isOnline);
        enqueueSnackbar(`You are now ${isOnline ? 'online' : 'offline'}`, { variant: 'info' });
      }),
    [enqueueSnackbar],
  );

  return <Context.Provider value={isOnline}>{children}</Context.Provider>;
}

export function useOnlineConnection() {
  return useContext(Context);
}
