import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '.';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Data Access Control',
    },
  ],
  getParentRoute: () => AccountDashboardRoute,
  path: 'data-access',
}).lazy(() => import(/* webpackChunkName: "data-access" */ './data-access.lazy').then(({ Route }) => Route));
