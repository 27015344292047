import { useAccountUser } from '../routes/_authenticated/accounts_/$accountId';
import { AccountPermission, AccountPrivilege } from '../utils/constants/access-control';

export function useAccessControl({
  privileges: accountPrivileges,
  permissions: accountPermissions,
}: {
  privileges?: AccountPrivilege[];
  permissions?: AccountPermission[];
}): { match: true } | { match: false; privileges?: AccountPrivilege[]; permissions?: AccountPermission[] } {
  const {
    account: { privileges = [], permissions = [] },
  } = useAccountUser();

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if (!(accountPrivileges?.length || accountPermissions?.length)) {
    throw new Error("useAccessControl(): 'privileges' and/or 'permissions' are required when using this hook.");
  }
  const missingPrivileges = accountPrivileges?.filter((p) => !privileges.includes(p));
  const missingPermissions = accountPermissions?.filter((p) => !permissions.includes(p));

  // Check if the user hasn't got the 'CLOUD_RBAC' privilege, and there are missing permissions.
  // Remove this check when we do not depend on the 'CLOUD_RBAC' privilege to apply permission checking.
  if (!privileges.includes('CLOUD_RBAC') && missingPermissions?.length) {
    missingPermissions.length = 0;
  }

  if (missingPrivileges?.length || missingPermissions?.length) {
    return { match: false, privileges: missingPrivileges, permissions: missingPermissions };
  }

  return { match: true };
}
