import { Link, Button } from '@mui/material';
import { ButtonPropsVariantOverrides } from '@mui/material/Button/Button';
import { OverridableStringUnion } from '@mui/types';
import { useParams } from '@tanstack/react-router';
import { ReactNode, memo } from 'react';
import { useTrackInteractionEvent } from '../../hooks/use-event-tracking';
import { useAccountId } from '../../routes/_authenticated/accounts_/$accountId';

export const buildSupportLink = (body?: { accountId?: string; clusterId?: string }) => {
  const searchParams = new URLSearchParams();
  if (body) {
    if (body.accountId) {
      searchParams.set('account_id', encodeURIComponent(body.accountId));
    }
    if (body.clusterId) {
      searchParams.set('cluster_id', encodeURIComponent(body.clusterId));
    }
  }
  return decodeURIComponent(searchParams.toString());
};

export const GetSupportLink = memo(function GetSupportLink({
  children = 'Get Support',
  color = 'secondary',
  fullWidth = true,
  variant = 'contained',
}: {
  children?: ReactNode;
  color?: 'primary' | 'secondary';
  subject?: string;
  fullWidth?: boolean;
  variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
}) {
  const url = new URL('https://support.qdrant.io/support/tickets/new');
  const accountId = useAccountId();
  const { clusterId } = useParams({ strict: false });
  url.search = buildSupportLink({ accountId, clusterId });

  const trackClick = useTrackInteractionEvent({
    label: 'Get Support',
    location: 'Sidebar',
    action: 'clicked',
  });

  return (
    <Link href={url.toString()} rel="noreferrer" target="_blank" underline="none" onClick={() => trackClick()}>
      <Button color={color} fullWidth={fullWidth} variant={variant}>
        {children}
      </Button>
    </Link>
  );
});
