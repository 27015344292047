import { createRoute } from '@tanstack/react-router';
import { LegalComponent } from './legal';
import { Route as RootRoute } from '../__root';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Legal',
    },
  ],
  getParentRoute: () => RootRoute,
  path: 'legal',
  component: LegalComponent,
});
