import { CSSProperties } from 'react';
import { ReactComponent as LogoSvgForDark } from './logo_with_text_dark_theme.svg';
import { ReactComponent as LogoSvgForLight } from './logo_with_text_light_theme.svg';

export const Logo = ({
  variant = 'primary',
  style,
  className,
}: {
  variant?: 'light' | 'primary';
  style?: CSSProperties;
  className?: string;
}) =>
  variant === 'light' ? (
    <LogoSvgForLight width="100%" height="100%" style={style} className={className} />
  ) : (
    <LogoSvgForDark width="100%" height="100%" style={style} className={className} />
  );
