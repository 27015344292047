import { createRoute } from '@tanstack/react-router';
import { Route as AccountDashboardRoute } from '.';
import { getPaymentInformationQuery } from '../../api/services/payment';
import { withRouteAccessControl } from '../../router/withRouteAccessControl';

export const Route = withRouteAccessControl(
  createRoute({
    meta: () => [
      {
        title: 'Billing',
      },
    ],
    getParentRoute: () => AccountDashboardRoute,
    path: 'billing',
    staticData: {
      permissions: ['read:payment_information'],
    },
    beforeLoad({ params: { accountId } }) {
      return { paymentInformationQueryOptions: getPaymentInformationQuery({ account_id: accountId }) };
    },
    loader({ context: { queryClient, paymentInformationQueryOptions } }) {
      void queryClient.prefetchQuery(paymentInformationQueryOptions);
    },
  }),
).lazy(() => import(/* webpackChunkName: "billing" */ './billing.lazy').then(({ Route }) => Route));
