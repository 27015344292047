import { queryOptions } from '@tanstack/react-query';
import { FromPathParams, QueryData, QueryOptions, queryKey } from './utils';

export const endpoints = {
  clusterByAccountId: '/accounts/{account_id}/clusters',
  clusterById: '/accounts/{account_id}/clusters/{cluster_id}',
  clusterScalable: '/accounts/{account_id}/clusters/{cluster_id}/scalable',
} as const;

export type ClusterData = QueryData<typeof endpoints.clusterById>;
export type ClusterScalableData = QueryData<typeof endpoints.clusterScalable>;

export const getClusterScalableQuery = (params: FromPathParams<QueryOptions<typeof endpoints.clusterScalable>>) =>
  queryOptions<ClusterScalableData>({ queryKey: queryKey.get(endpoints.clusterScalable, params), retry: false });
