import { MaybeOptionalInit, ParamsOption, ParseAsResponse, RequestOptions } from 'openapi-fetch';
import { HttpMethod, MediaType, PathsWithMethod, ResponseObjectMap, SuccessResponse } from 'openapi-typescript-helpers';
import { paths } from '../../api-schema';
import { objectEntries, objectFromEntries } from '../../utils/func-utils';

export type QueryData<
  Path extends PathsWithMethod<paths, Method>,
  Method extends HttpMethod = 'get',
  Media extends MediaType = MediaType,
> = ParseAsResponse<
  SuccessResponse<ResponseObjectMap<paths[Path][Method]>, Media>,
  MaybeOptionalInit<paths[Path], Method>
>;

export type QueryOptions<Path extends PathsWithMethod<paths, Method>, Method extends HttpMethod = 'get'> = ParamsOption<
  paths[Path][Method]
>;

export type MutationOptions<
  Path extends PathsWithMethod<paths, Method>,
  Method extends HttpMethod = 'post',
> = RequestOptions<paths[Path][Method]>;

export type FromPathParams<T> = T extends {
  params: {
    path: Record<string, unknown>;
  };
}
  ? T['params']['path']
  : never;

type FromQueryParams<T> = T extends {
  params: {
    query: Record<string, unknown>;
  };
}
  ? T['params']['query']
  : never;

export function toPathParams<TParams extends Record<string, unknown>>(
  params: TParams,
): {
  params: { path: TParams };
};
export function toPathParams<TKey extends string, TParams extends Record<TKey, unknown>>(
  params: TParams,
  options?: { filterKeys: TKey[] },
): {
  params: { path: Pick<TParams, TKey> };
};
export function toPathParams<TKey extends string, TParams extends Record<TKey, unknown>>(
  params: TParams,
  options?: { filterKeys: TKey[] },
) {
  if (!options || options.filterKeys.length === 0) {
    return { params: { path: params } };
  }
  return {
    params: {
      path: objectFromEntries(objectEntries(params).filter(([key]) => options.filterKeys.includes(key as TKey))),
    },
  };
}

export function toQueryParams<TQuery extends Record<string, unknown>>(query: TQuery) {
  return { params: { query } };
}

export const queryKey = {
  post: <TMethods extends HttpMethod = 'post'>(
    path: PathsWithMethod<paths, TMethods>,
    params: FromPathParams<MutationOptions<typeof path, TMethods>>,
  ) => [path, toPathParams(params)],
  delete: <TMethods extends HttpMethod = 'delete'>(
    path: PathsWithMethod<paths, TMethods>,
    params: FromPathParams<MutationOptions<typeof path, TMethods>>,
  ) => [path, toPathParams(params)],
  patch: <TMethods extends HttpMethod = 'patch'>(
    path: PathsWithMethod<paths, TMethods>,
    params: FromPathParams<MutationOptions<typeof path, TMethods>>,
  ) => [path, toPathParams(params)],
  get: <TMethods extends HttpMethod = 'get'>(
    path: PathsWithMethod<paths, TMethods>,
    params: FromPathParams<QueryOptions<typeof path, TMethods>>,
    // eslint-disable-next-line @stylistic/js/max-len
    query?: FromQueryParams<QueryOptions<typeof path, TMethods>>, // Query should not be possible when it's defined as never in the apiSchema
  ) => [path, toPathParams(params), ...[query ? toQueryParams(query) : undefined]],
};
