import { createRoute } from '@tanstack/react-router';
import { Route as HybridCloudEnvironmentsRoute } from '../hybrid-cloud-environments_';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Hybrid Cloud Environments',
    },
  ],
  getParentRoute: () => HybridCloudEnvironmentsRoute,
  path: '/',
}).lazy(() => import(/* webpackChunkName: "hybrid-cloud-envs-index" */ './index.lazy').then(({ Route }) => Route));
