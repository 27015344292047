import { styled } from '@mui/material';
import { HTMLAttributes, ReactNode } from 'react';

const ButtonLikeStyled = styled('div')(
  ({ theme }) => `
    cursor: pointer;
    display: flex;
    transition: ${theme.transitions.create(['background-color', 'border-color', 'outline-color'], {
      duration: theme.transitions.duration.shorter,
    })};
    border: 1px solid transparent;

    &[aria-disabled='true'] {
      pointer-events: none;
      user-select: none;
      opacity: 0.6;
    }
`,
);

export type ButtonLikeProps<T> = {
  children: ReactNode;
  value?: T;
  onClick?: (value: T) => unknown;
  className?: string;
  pressed?: HTMLAttributes<HTMLDivElement>['aria-pressed'];
  disabled?: HTMLAttributes<HTMLDivElement>['aria-disabled'];
  ariaLabel?: HTMLAttributes<HTMLDivElement>['aria-label'];
};

export function ButtonLike<T>({
  children,
  onClick,
  value,
  pressed,
  disabled,
  className,
  ariaLabel,
}: ButtonLikeProps<T>) {
  return (
    <ButtonLikeStyled
      role="button"
      aria-label={ariaLabel}
      tabIndex={disabled ? -1 : 0}
      className={className}
      onClick={(event) => {
        event.preventDefault();
        if (onClick) {
          onClick(value as T);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          if (onClick) {
            onClick(value as T);
          }
        }
      }}
      aria-pressed={pressed}
      aria-disabled={disabled}
    >
      {children}
    </ButtonLikeStyled>
  );
}
