import { AuthenticationError, OAuthError } from '@auth0/auth0-react';
import { createRoute, redirect } from '@tanstack/react-router';
import { LibErrorCodes, SpecErrorCodes } from 'auth0-js';
import { authMiddleware } from '../../api/client';
import { AuthenticatedErrorComponent } from '../../components/AccountDashboard/AuthenticatedErrorComponent';
import { setToken } from '../../reducers/token';
import { createRouteErrorComponent } from '../../router/createRouteErrorComponent';
import { ONE_HOUR_MILLISECONDS, authRedirectUrl } from '../../utils/constants';
import { captureException } from '../../utils/error-utils';
import { Route as RootRoute } from '../__root';

function isContextWithLastModified(context: object): context is { lastModified: number } {
  return context !== null && 'lastModified' in context && typeof context.lastModified === 'number';
}

export const Route = createRoute({
  getParentRoute: () => RootRoute,
  id: 'authenticated',
  context: ({ context }) => ({
    lastModified: isContextWithLastModified(context) ? context.lastModified : Infinity,
  }),
  async beforeLoad({ context: { auth, store, lastModified } }) {
    if (auth.error) {
      const isAccessDenied = isAuthenticationError(auth.error) && auth.error.error === 'access_denied';
      // Something went wrong on the auth0 side (or network request failed), send to Sentry and logout.
      captureException(auth.error, {
        tags: { context: 'auth', action: 'authenticate', route: window.location.toString() },
        // Access denied can happen if the user fails to be authorized in Auth0.
        level: isAccessDenied ? 'info' : 'fatal',
        extra: {
          error: isAuthenticationError(auth.error) ? auth.error.error_description : undefined,
        },
      });
    }
    if (!auth.isAuthenticated) {
      throw redirect({
        to: '/login',
        search: isAuthenticationError(auth.error) ? { aerr: auth.error.error_description } : undefined,
      });
    }
    // Check if one hour has elapsed to reload the data.
    const now = Date.now();
    const oneHourElapsed = now >= lastModified + ONE_HOUR_MILLISECONDS;
    lastModified = now;
    // Load token from cache if available, otherwise force a network request.
    const cacheMode = oneHourElapsed ? 'off' : 'on';
    // Get access token silently, if it fails, it will be handled by the 'onError' callback.
    const token = await auth.getAccessTokenSilently({
      cacheMode,
      authorizationParams: {
        redirect_uri: authRedirectUrl,
        audience: window.__QDRANT_CLOUD__.auth0.audience,
        scope: window.__QDRANT_CLOUD__.auth0.scope,
      },
    });
    // Store token in redux store for RTK's api base query to use.
    store.dispatch(setToken(token));
    // Set the token in the authMiddleware to be used in the API client.
    authMiddleware.token = token;
    return { lastModified };
  },
  onError(err) {
    // See https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4
    if (isOAuthError(err)) {
      const authError = err.error as LibErrorCodes | SpecErrorCodes | 'mfa_required';
      const authErrorDesc = err.error_description;
      switch (authError) {
        case 'login_required' /* https://github.com/auth0/auth0-spa-js/blob/fbe1344/src/Auth0Client.ts#L928 */:
        case 'invalid_token' /* Token expired */:
        case 'consent_required':
          throw redirect({ to: '/logout', search: { aerr: authErrorDesc ?? authError } });
        /*
         * Thrown when network requests to the Auth server timeout.
         * It can happen if adquiring a lock between tabs takes too long, see:
         * https://github.com/auth0/auth0-spa-js/blob/fbe1344/src/Auth0Client.ts#L689
         * or if the network request to the Auth server times out, see:
         * https://github.com/auth0/auth0-spa-js/blob/fbe1344/src/Auth0Client.ts#L897C50-L897C75
         * Adcquiring a lock between tabs can take up 50 secs before throwing, so we
         * set `authorizeTimeoutInSeconds` to 10 secs to minimize the chances of this happening.
         */
        case 'timeout':
        case 'request_error':
          return; // do nothing, let the user try again in 1 hour or it will lead to 401 and logout.
        /* The next ones are considered 'recoverable errors': */
        case 'mfa_required' /* https://github.com/auth0/auth0-spa-js/blob/f4f233d/src/http.ts#L145 */:
        case 'interaction_required':
        case 'account_selection_required':
          onTokenAccessError(err);
          throw redirect({ to: '/logout' });
        default:
      }
      switch (authErrorDesc) {
        case 'Login required':
        case 'Invalid authorization code':
          // It's clear what to do here, logout and redirect to login.
          throw redirect({ to: '/logout', search: { aerr: authErrorDesc } });
        default:
      }
    }
  },
  errorComponent: createRouteErrorComponent(AuthenticatedErrorComponent),
});

// See: https://github.com/auth0/auth0-react/blob/main/src/utils.tsx#L18
function isOAuthError(err: unknown): err is OAuthError {
  return err != null && typeof err === 'object' && 'error' in err && typeof err.error === 'string';
}

function isAuthenticationError(err: unknown): err is AuthenticationError {
  return err != null && err instanceof AuthenticationError;
}

// Something we didn't account for happened, send to Sentry and proceed.
function onTokenAccessError(error: unknown) {
  captureException(error, {
    level: 'error',
    tags: { context: 'auth', action: 'get-access-token-silently', route: window.location.toString() },
    extra: { error: isOAuthError(error) ? error.error_description : undefined },
  });
}
