import { Badge, IconButton, Menu, Tooltip } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { NotificationsMenu } from './NotificationsMenu';
import { Bell as BellIcon } from '../../../icons/bell';

// todo: When the notification API is ready
// Unread notifications should come from a context and be shared with both this component and
// notifications popover. To simplify the demo, we get it from the popover
export const NotificationsButton = () => {
  const [unread, setUnread] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUpdateUnread = (value: number) => {
    setUnread(value);
  };
  // See https://mui.com/material-ui/react-menu/#account-menu for more details
  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 1, height: 48 }}
          disableRipple={true}
          aria-controls={open ? 'notifications-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Badge color="error" badgeContent={unread}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="notifications-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: 280,
              maxWidth: 380,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(100, 116, 139, 0.32))',
              mt: 1,
              ml: 1,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 16,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transitionDuration={0}
      >
        <NotificationsMenu onUpdateUnread={handleUpdateUnread} />
      </Menu>
    </>
  );
};
