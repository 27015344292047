import { LinearProgress, styled } from '@mui/material';
import { memo } from 'react';

export const LoadingIndicator = styled(memo(LinearProgress))({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
});
