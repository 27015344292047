import { createRoute } from '@tanstack/react-router';
import { Route as ClustersRoute } from '../clusters_';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Clusters',
    },
  ],
  getParentRoute: () => ClustersRoute,
  path: '/',
}).lazy(() => import(/* webpackChunkName: "clusters" */ './index.lazy').then(({ Route }) => Route));
