import { createRoute, redirect } from '@tanstack/react-router';
import { Route as AuthenticatedRoute } from '..';
import { getPaymentSessionQuery, PaymentSessionData } from '../../../api/services/payment';
import { enqueueSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { accountSearchSchema } from '../../../router/utils';
import { iamApi } from '../../../services/iamApi';
import { getUserIdBucket } from '../../../utils/analytics-utils';
import { captureException } from '../../../utils/error-utils';
import { createAccountUser, fetchAccountUser } from '../_account';

/**
 * Parent route for sub-routes that require the account user data, and HAVE the account id in the URL.
 */
export const userIdBucketCache = new Map<string, number>();

export const Route = createRoute({
  getParentRoute: () => AuthenticatedRoute,
  path: 'accounts/$accountId',
  validateSearch: (search) => accountSearchSchema.parse(search),
  /**
   * Load the user information, or create a new user if it doesn't exist.
   * Fetch errors are handled in the parent route's error boundary.
   */
  async beforeLoad({
    context: { auth, store, queryClient },
    params: { accountId: account_id },
    search: { session_id },
  }) {
    if (session_id) {
      await ensurePaymentSession(queryClient.fetchQuery(getPaymentSessionQuery({ account_id, session_id })));
    }
    const accountUser = await fetchAccountUser({ store, accountId: account_id });
    if (accountUser) {
      const userBucket = await getUserIdBucket({ userId: accountUser.id, cacheMap: userIdBucketCache });
      return { userBucket };
    }
    await createAccountUser({ auth, store });
    return { userBucket: null };
  },
}).lazy(() => import(/* webpackChunkName: "account" */ './$accountId.lazy').then(({ Route }) => Route));

/**
 * Stripe redirects to the same page with the session_id in the URL.
 * Anytime we get a successful session with succeeded payment intent we must store the payment method.
 * It removes the session_id from the URL and redirects to the same page.
 * @see https://github.com/qdrant/qdrant-cloud-cluster-api/blob/af86f601965d190a20944286390ff742fd7d2cbf/cluster_api/booking/payment/stripe_/service_fn.py#L173
 */
export async function ensurePaymentSession(paymentSession: Promise<PaymentSessionData>): Promise<never> {
  let hasError = false;
  try {
    const data = await paymentSession;
    const isSuccess = isStripeSetupIntentObject(data.setup_intent) && data.setup_intent.status === 'succeeded';

    if (!isSuccess) {
      hasError = true;
      captureException(new Error(`Stripe setup failed: ${JSON.stringify(data)}`), {
        level: 'fatal',
      });
    }
  } catch (error) {
    hasError = true;
    captureException(error);
  }
  if (hasError) {
    enqueueSnackbar(
      'Unfortunately your payment setup failed. ' +
        'Please try again in a few moments. ' +
        'If the problem persist, contact support.',
      {
        variant: 'error',
      },
    );
  }

  throw redirect({ search: (prev) => ({ ...prev, session_id: undefined }), hash: true, replace: true });
}

type StripeSetupIntent = PaymentSessionData['setup_intent'];

/**
 * The field `setup_intent` can be expanded into an object with the `expand` request parameter.
 */
function isStripeSetupIntentObject(setupIntent: StripeSetupIntent): setupIntent is Exclude<StripeSetupIntent, string> {
  return typeof setupIntent !== 'string' && typeof setupIntent.status === 'string';
}

export const useAccountUser = () => {
  const accountId = Route.useParams({ select: ({ accountId }) => accountId });
  const { accountUser } = iamApi.endpoints.getUserInfo.useQueryState(
    { account_id: accountId },
    { selectFromResult: ({ currentData }) => ({ accountUser: currentData }) },
  );
  if (!accountUser) {
    throw new Error('useAccountUser(): account user data is not available');
  }

  return accountUser;
};

export const useAccountId = () => useAccountUser().account.id;

export const useUserSegmentation = () => {
  const userBucket = Route.useRouteContext({ select: ({ userBucket }) => userBucket });
  if (userBucket === null) {
    throw new Error('useUserSegmentation(): userBucket is not available');
  }
  return userBucket;
};
