import { createRoute } from '@tanstack/react-router';
import { withRouteAccessControl } from '../../../router/withRouteAccessControl';
import { Route as CloudAccessRoute } from '../cloud-access_';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => CloudAccessRoute,
    path: 'programmatic-access',
    staticData: {
      privileges: ['PROGRAMMATIC_ACCESS'],
    },
  }),
).lazy(() =>
  import(/* webpackChunkName: "programmatic-access" */ './programmatic-access.lazy').then(({ Route }) => Route),
);
